export const softShadows = [
  'none',
  '0 1px 1px -1px rgba(0,0,0,0.25)',
  '0 2px 2px -2px rgba(0,0,0,0.25)',
  '0 3px 4px -2px rgba(0,0,0,0.25)',
  '0 3px 4px -2px rgba(0,0,0,0.25)',
  '0 4px 6px -2px rgba(0,0,0,0.25)',
  '0 4px 6px -2px rgba(0,0,0,0.25)',
  '0 4px 8px -2px rgba(0,0,0,0.25)',
  '0 5px 8px -2px rgba(0,0,0,0.25)',
  '0 6px 12px -4px rgba(0,0,0,0.25)',
  '0 7px 12px -4px rgba(0,0,0,0.25)',
  '0 6px 16px -4px rgba(0,0,0,0.25)',
  '0 7px 16px -4px rgba(0,0,0,0.25)',
  '0 8px 18px -8px rgba(0,0,0,0.25)',
  '0 9px 18px -8px rgba(0,0,0,0.25)',
  '0 10px 20px -8px rgba(0,0,0,0.25)',
  '0 11px 20px -8px rgba(0,0,0,0.25)',
  '0 12px 22px -8px rgba(0,0,0,0.25)',
  '0 13px 22px -8px rgba(0,0,0,0.25)',
  '0 14px 24px -8px rgba(0,0,0,0.25)',
  '0 16px 28px -8px rgba(0,0,0,0.25)',
  '0 18px 30px -8px rgba(0,0,0,0.25)',
  '0 20px 32px -8px rgba(0,0,0,0.25)',
  '0 22px 34px -8px rgba(0,0,0,0.25)',
  '0 24px 36px -8px rgba(0,0,0,0.25)'
];

export const strongShadows = [
  'none',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 12px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 12px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 16px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 16px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 8px 18px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 9px 18px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 10px 20px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 11px 20px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 12px 22px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 13px 22px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 14px 24px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 16px 28px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 18px 30px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 20px 32px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 22px 34px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 24px 36px -8px rgba(0,0,0,0.50)'
];
