import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
  actions.updateRequesting,
]

export const updateRequesting = [
  //
  actions.updateRequesting,
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Shuttle request saved', type: 'success' }),
  route.toLocationRouteRequests,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Shuttle request deleted', type: 'success' }),
  findForList,
]

export const dispatchShuttle = [
  //
  actions.dispatchShuttle,
  saveEntities(),
  showFeedback({ title: 'Shuttle has been dispatched', type: 'success' }),
  route.toLocationRouteRequests,
]

export const updateEntities = [
  //
  saveEntities(),
]

export const updateList = [
  //
  actions.setList,
]
