import { moduleState } from 'cerebral'
import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove, updateOrder } = crudActions({ name: 'contactInfos' })

export const toggleContactField = async ({ props: { id, field = '' }, contactInfosService: service, get }) => {
  if (id) {
    if (field !== 'isPrimaryContact' && field !== 'isEmergencyContact') {
      throw new Error('Contact field can not be toggled')
    }

    let data

    if (field === 'isEmergencyContact') {
      const isEmergencyContact = get(moduleState`entities.${id}.isEmergencyContact`)
      data = await service.patch(id, { isEmergencyContact: !isEmergencyContact })
    } else if (field === 'isPrimaryContact') {
      const isPrimaryContact = get(moduleState`entities.${id}.isPrimaryContact`)
      data = await service.patch(id, { isPrimaryContact: !isPrimaryContact })
    }

    return { entities: { contactInfos: [data] } }
  }
}
