import { set, when } from 'cerebral/factories'
import { props, state } from 'cerebral'

import * as actions from './actions'
import { redirectOnSave, saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.savePDF,
  saveEntities(),
  showFeedback({ title: 'Publication saved', type: 'success' }),
  route.toPublications,
]

export const saveWithoutFeedback = [
  //
  actions.save,
  actions.savePDF,
  saveEntities(),
  when(state`route.path`, redirectOnSave),
  {
    true: [
      ({ props: { entities } }) => {
        const [result] = entities.publications
        // Redirect back to appropriate publication.
        return { params: { id: result?._id } }
      },
      route.toPublication,
    ],
    false: [],
  },
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Publication deleted', type: 'success' }),
  findForList,
]

export const getEditorToken = [
  //
  actions.getEditorToken,
]
