const yup = require('yup')
const { labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  slug: yup.string().default(''),
  browseContentURL: yup.string().default(''),
  supportsIframe: yup.boolean().default(false),
  supportsPlayingInBackground: yup.boolean().default(false),
})

const libraryProviderSchema = yup.object().shape(shape)

module.exports = { libraryProviderSchema }
