import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  none: {
    color: theme.palette.grey[500],
  },
}))

const StatusText = ({ color, className = '', ...rest }) => {
  const classes = useStyles()
  return <Typography {...rest} className={cc([className, classes[color]])}/>
}

StatusText.propTypes = {
  color: PropTypes.oneOf(['warning', 'success', 'error', 'none', 'inherit']).isRequired,
  className: PropTypes.string,
}

export default StatusText
