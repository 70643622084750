import { props } from 'cerebral'
import { set } from 'cerebral/factories'
import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { loadProfilesSequence } from '../profiles/factories'
import { compose, path, pluck } from 'ramda'

const loadProfiles = loadProfilesSequence(compose(pluck('profile'), path(['props', 'entities', 'appointments'])), 'profileIds')

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const findForCalendar = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  saveEntities(),
  loadProfiles,
  actions.mapCalendarFields,
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Appointment saved', type: 'success' }),
  route.toAppointmentCalendar,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Appointment deleted', type: 'success' }),
  findForList,
  route.toAppointmentCalendar,
]

export const togglePublished = [
  //
  actions.togglePublished,
  showFeedback({ title: 'Appointment saved', type: 'success' }),
  saveEntities(),
  findForList,
]

export const changeTime = [
  //
  actions.changeTime,
  showFeedback({ title: `Appointment changed`, type: 'success' }),
]
