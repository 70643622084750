const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const timeField = require('./fields/time-field')
const { uploadSchema } = require('./cloudinary-schema')

const linkSchema = yup.object().shape({
  label: yup.string().required('Website label is required').default(''),
  href: yup.string().required('Website address is required').default(''),
})

const operationHourSchema = yup.object().shape({
  open: timeField(),
  close: timeField(),
  rrule: yup.object().shape({
    freq: yup
      .number()
      .transform(() => 2)
      .default(2),
    interval: yup.number().default(1),
    byweekday: yup.array().default([0, 1, 2, 3, 4, 5, 6]),
  }),
})

const shape = labelify({
  name: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  operationHours: yup.array().of(operationHourSchema),
  area: yup.mixed(),
  images: yup.array().of(uploadSchema).default([]),
  description: yup.string().max(400).meta({ maxLength: 400 }).default(''),
  phone: yup.string().default(''),
  links: yup.array().of(linkSchema),
  coordinates: yup.object().required().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
  categories: yup.array().of(yup.mixed()).default([]),
  active: yup.boolean().default(true),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const mapPointSchemaPartial = yup.object().shape(shape)
const mapPointSchema = mapPointSchemaPartial.shape(requiredFields(['name'])(shape))

const mapPointFormSchema = mapPointSchema.shape({
  area: yup.mixed().transform((v) => (v && v._id) || v || ''),
})

module.exports = { mapPointSchema, mapPointSchemaPartial, mapPointFormSchema, linkSchema, operationHourSchema }
