import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'

const setMessage = (message) => ({ get }) => {
  const typeTitle = get(state`account.labels.resources.singular`) || 'Resource'
  return { feedbackMessage: `${typeTitle} ${message}` }
}

export const find = [
  actions.find,
  saveEntities(),
]

export const findForList = [
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  actions.get,
  saveEntities(),
]

export const save = [
  actions.save,
  actions.clearList,
  saveEntities(),
  setMessage('saved'),
  showFeedback({ type: 'success' }),
  set(props`params.resourceGroup`, props`entities.resources.0.resourceGroup`),
  route.toResources,
]

export const remove = [
  actions.remove,
  setMessage('deleted'),
  showFeedback({ type: 'success' }),
  findForList,
]

export const updateOrder = [
  actions.updateOrder,
  findForList,
]
