const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  _id: yup.string(),
  name: yup.string().default(''),
  limitPerSlot: yup.number().nullable(),
  limitPerReservation: yup.number().nullable(),
  restrictToUserType: yup.string().ensure(),
})

const reservationScheduleSchemaPartial = yup.object().shape(shape)
const reservationScheduleSchema = reservationScheduleSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { reservationScheduleSchema, reservationScheduleSchemaPartial }
