import { state } from 'cerebral'

export const showFeedback = ({ title, body, type = 'info' }) => [
  ({ props, store }) => {
    store.push(state`feedback.messages`, { title: title || props.feedbackMessage, body, type, dismissed: false, createdAt: new Date() })
  },
]

export const showFeedbackFromError = ({ title, ignore }) => [
  ({
    props: {
      error: { message },
    },
    store,
  }) => {
    if (!ignore || !message.match(ignore)) {
      store.push(state`feedback.messages`, { title, body: message, type: 'error', dismissed: false, createdAt: new Date() })
    }
  },
]
