const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.mixed(),
  locationRouteRequest: yup.mixed(),
  locationVehicleShift: yup.mixed(),
  estimatedTravelSeconds: yup.number(),
  estimatedTravelDistance: yup.number(),
  locationName: yup.string().ensure(),
  requestedAt: yup.date(),
  acceptedAt: yup.date(),
  declinedAt: yup.date(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationRouteDispatchRequestSchemaPartial = yup.object().shape(shape)
const locationRouteDispatchRequestSchema = locationRouteDispatchRequestSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationRouteDispatchRequestSchema, locationRouteDispatchRequestSchemaPartial }
