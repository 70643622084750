const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  icon: yup.string().ensure().default('Info'),
  sortOrder: yup.number().min(0),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const resourceGroupSchemaPartial = yup.object().shape(shape)
const resourceGroupSchema = resourceGroupSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { resourceGroupSchema, resourceGroupSchemaPartial }
