const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  mileage: yup.number().min(0),
  locationVehicleMaintenanceSchedule: yup.mixed(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationVehicleMaintenanceLogSchemaPartial = yup.object().shape(shape)
const locationVehicleMaintenanceLogSchema = locationVehicleMaintenanceLogSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationVehicleMaintenanceLogSchema, locationVehicleMaintenanceLogSchemaPartial }
