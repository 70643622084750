import React from 'react'
import { connect } from '@cerebral/react'
import { state } from 'cerebral'
import { Avatar } from '@mui/material'
import { omitConnectProps } from '../../lib/util/cerebral'
import { initials } from '../../lib/util/format'

const UserAvatar = connect(
  {
    user: state`account.currentUser`,
  },

  ({ user, ...rest }) => user ? (
    <Avatar alt="User" {...omitConnectProps(rest)}>
      {initials(user.name)}
    </Avatar>
  ) : null
)

export default UserAvatar
