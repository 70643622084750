const yup = require('yup')
const displayLocation = require('./fields/display-location')
const { requiredFields, labelify } = require('./helpers')
const { nestedNotificationSchema } = require('./notification-schema')

const shape = labelify({
  alertType: yup.string().oneOf(['weather', 'emergency', 'information']).default('weather'),
  alertMessage: yup.string().max(100).meta({ maxLength: 100 }),
  additionalInfo: yup.string().max(400).meta({ maxLength: 400 }),
  displayLocation: displayLocation(),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  filterSignDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  signDevices: yup.array().of(yup.string()).default([]),
  expires: yup.string().oneOf(['midnight', 'onehour']).default('onehour'),
})

const alertSchemaPartial = yup.object().shape(shape)
const alertSchema = alertSchemaPartial.shape(requiredFields(['alertType', 'alertMessage', 'additionalInfo'])(shape)).shape(nestedNotificationSchema)

module.exports = { alertSchema, alertSchemaPartial }
