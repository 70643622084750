import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
}))

const PageSpacing = ({ children }) => {
  const classes = useStyles()

  return (
    <Container classes={{ root: classes.Container }}>
      <Box maxWidth="100%" flex={1} my={[2, 4]}>
        {children}
      </Box>
    </Container>
  )
}

PageSpacing.propTypes = {
  children: PropTypes.node,
}

export default PageSpacing
