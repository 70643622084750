import { crudActions } from '../factories'
import apiClient from '../../lib/util/api-client'
import { moduleState } from 'cerebral'
import { indexBy, pluck, prop } from 'ramda'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'capacityLocations' })

const typeService = apiClient.service('generic-types')

export const getTypes = async ({ store }) => {
  const { data } = await typeService.find({ query: { typeCategory: 'location' } })
  store.set(moduleState`locationTypeIds`, pluck('_id', data))
  store.set(moduleState`locationTypes`, indexBy(prop('_id'), data))
}
