import { moduleState, state } from 'cerebral'
import { findIndex, equals } from 'ramda'

export const showFeedback = ({ props, store }) => {
  const { title, body, type = 'success' } = props
  store.push(state`feedback.messages`, { title, body, type, dismissed: false, createdAt: new Date() })
}

export const dismissCurrentMessage = ({ get, store }) => {
  const messages = get(moduleState`messages`)
  const currentMessage = get(moduleState`currentMessage`)
  const index = findIndex(equals(currentMessage), messages)
  if (index > -1) {
    store.splice(moduleState`messages`, index, 1)
  }
}

export const clearError = ({ props: { errorPath }, store }) => {
  store.unset(state`errors.${errorPath}`)
}
