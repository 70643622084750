import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@mui/material'

export const CANCEL_CONFIRM = { cancel: true }

const ConfirmDialog = ({
  title,
  confirmText = 'Continue',
  cancelText = 'Cancel',
  onClose,
  onConfirm,
  closeOnConfirm = true,
  children,
  helperAction = <></>,
  confirmButtonProps = {},
  ...rest
}) => {
  return (
    <Dialog onClose={onClose} {...rest} aria-labelledby="confirm-dialog-title">
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {helperAction}
        <div style={{ flex: '1 0 0' }} />
        {cancelText !== false && (
          <Button variant="text" onClick={onClose} color="primary" type="button">
            {cancelText}
          </Button>
        )}
        <Button
          onClick={async () => {
            const shouldClose = await onConfirm()
            if (shouldClose !== CANCEL_CONFIRM && closeOnConfirm) {
              onClose()
            }
          }}
          color="primary"
          type="button"
          {...confirmButtonProps}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  helperAction: PropTypes.node,
  confirmText: PropTypes.node,
  confirmButtonProps: PropTypes.object,
  cancelText: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeOnConfirm: PropTypes.bool,
}

export default ConfirmDialog
