import React from 'react'
import { CircularProgress, Box } from '@mui/material'

const LoadingScreen = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" style={{ minHeight: '50vh' }}>
      <CircularProgress color="inherit" />
    </Box>
  )
}

export default LoadingScreen
