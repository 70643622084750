import React from 'react'
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { omit } from 'ramda'

// TODO: This essentially duplicates ThemedButton. DRY.

const useStyles = makeStyles((theme) => ({
  danger: {
    color: theme.palette.error.light,
  },
  emphasized: {
    backgroundColor: theme.palette.grey['400'],
  },
}))

const ThemedIconButton = (props) => {
  const classes = useStyles()

  let colorStyle = classes[props.color]
  if (colorStyle) {
    props = omit(['color'], props)
    props.className = `${colorStyle} ${props.className || ''}`
  }

  return <IconButton {...props} size="large" />;
}

ThemedIconButton.propTypes = IconButton.propTypes

export default ThemedIconButton
