const { fromPairs } = require('ramda')

const daysOfWeekKeys = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
const daysOfWeekIndexes = fromPairs(daysOfWeekKeys.map((day, index) => [day, index]))

// RRule 0 index is Monday.
const rruleDaysOfWeekKeys = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
const rruleDaysOfWeekIndexes = fromPairs(rruleDaysOfWeekKeys.map((day, index) => [day, index]))

module.exports = { daysOfWeekKeys, daysOfWeekIndexes, rruleDaysOfWeekKeys, rruleDaysOfWeekIndexes }
