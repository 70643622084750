import { moduleState, state } from 'cerebral'
import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'locationRouteRequests' })

export const dispatchShuttle = async ({ props: { id, locationVehicle }, locationRouteRequestsService: service }) => {
  if (id) {
    const data = await service.patch(id, { status: 'dispatched', locationVehicle })
    return { entities: { locationRouteRequests: [data] } }
  }
}

export const updateRequesting = async ({ locationRouteRequestsService: service, store, get }) => {
  const vehiclesEnabled = get(state`account.packages.vehicles`)
  if (vehiclesEnabled) {
    const { total } = await service.find({ status: 'requesting', $limit: 0 })
    store.set(moduleState`requestingCount`, total || 0)
    // TODO: Remove this manual title modification. It is here to work around React Helmet not updating when the tab is not visible.
    if (document.visibilityState !== 'visible') {
      const title = document.title.replace(/^\(\d+\)\s+/, '')
      document.title = total ? `(${total}) ${title}` : title
    }
  }
}
