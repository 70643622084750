import { props } from 'cerebral'
import { equals, set } from 'cerebral/factories'
import * as actions from './actions'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  equals(props`values.schedule`),
  {
    draft: [
      //
      showFeedback({ title: 'Message saved as draft', type: 'success' }),
      set(props`params.filter`, 'draft'),
      route.toNotifications,
    ],
    otherwise: [
      //
      showFeedback({ title: 'Message queued for sending', type: 'success' }),
      set(props`params.filter`, 'active'),
      route.toNotifications,
    ],
  },
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Message deleted', type: 'success' }),
  findForList,
]

export const getRecipientCount = [
  //
  actions.getRecipientCount,
]
