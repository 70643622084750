import { connect } from '@cerebral/react'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { state } from 'cerebral'
import cc from 'classcat'
import { compose, flatten, fromPairs, map, pluck, unnest } from 'ramda'
import React, { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { camelize, capitalize } from 'underscore.string'
import format from '../../../../shared/format'
import brand from '../../../lib/util/brand'
import { exists, url } from '../../../lib/util/cloudinary'
import { routePaths } from '../../../modules/route'
import ConfirmDialog from '../../controls/ConfirmDialog'
import FlatCard from '../../elements/FlatCard'
import Image from '../../elements/Image'
import { navItems } from '../../layout/Nav'

const useStyles = makeStyles((theme) => ({
  gridCard: {
    display: 'flex',
    alignItems: 'stretch',
  },
  card: {
    width: '100%',
    minHeight: '15em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardInactive: {
    opacity: 0.5,
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    '& path, & g': {
      fill: 'currentColor',
      fillOpacity: 1,
    },
  },
  cardMedia: {
    height: 0,
    paddingTop: '50%',
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  cardContentWithoutImage: {
    marginTop: 0,
    paddingTop: 0,
  },
  markdown: {
    '& > :first-child': {
      marginTop: 0,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
}))

const unpackRoutes = ({ icon, title, route, match = [] }) => {
  const item = { Icon: icon, title }
  return [route].concat(match).map((route) => [route, item])
}
const buildRouteMatch = compose(fromPairs, unnest, map(unpackRoutes), flatten, pluck('items'))
const routeMap = buildRouteMatch(navItems)

// Override mappings for when package names don't automatically map to route names. Keys are package name.
const routeNamesMap = {
  attendance: 'toEventInstances',
  'calendar-print': 'toEventCalendar',
  capacity: 'toCapacityLocations',
  events: 'toEventCalendar',
  'events-repeat': 'toEventCalendar',
  media: 'toMedias',
  menu: 'toMealsCalendar',
  pricing: 'toPrices',
  'staff-appreciation': 'toAwards',
}

export default connect(
  {
    packages: state`account.packages`,
    userName: state`account.currentUser.name`,
    cards: state`info.cards`,
    messages: state`info.messages`,
  },

  ({ packages, userName, cards = [], messages = [] }) => {
    const classes = useStyles()
    const [modalVisible, setModalVisible] = useState('')
    const setModalClosed = useCallback(() => setModalVisible(''), [])
    userName = format.name(userName)

    return (
      <>
        <ConfirmDialog
          open={Boolean(modalVisible)}
          title={`${modalVisible} not enabled`}
          onConfirm={() => {}}
          onClose={setModalClosed}
          confirmText="Got it!"
          cancelText={false}
        >
          <Box padding={3} minWidth={300}>
            Sorry, you organization has not enabled {modalVisible}. To inquire about turning this feature on, contact{' '}
            <a href={`mailto:${brand.email}`}>{brand.email}</a> or chat live with us now.
          </Box>
        </ConfirmDialog>

        <Grid spacing={3} container>
          {cards.map(({ _id, title, description, buttonTitle, image, package: pkg }) => {
            const { name: packageName, slug } = pkg || {}
            const hasImage = exists(image)
            const enabled = Boolean(packages[slug])
            const routeName = routeNamesMap[slug] || `to${capitalize(camelize(slug))}`
            const { Icon, title: navTitle } = routeMap[routeName] || {}
            const href = routePaths[routeName] && routePaths[routeName]()
            buttonTitle = buttonTitle || `View ${navTitle}`

            return (
              <Grid key={_id} item md={4} classes={{ root: classes.gridCard }}>
                <FlatCard
                  classes={{
                    root: cc([
                      classes.card,
                      !enabled && classes.cardInactive,
                      'hook-landing-card',
                      `hook-landing-card-${slug}`,
                      !enabled && 'hook-landing-card-inactive',
                    ]),
                  }}
                >
                  <Box>
                    <CardContent classes={{ root: classes.titleContent }}>
                      {Icon && <Icon height="24" width="24" className={classes.icon} />}
                      <Typography variant="h4">{title}</Typography>
                    </CardContent>
                    {hasImage && (
                      <CardMedia classes={{ root: classes.cardMedia }} image={url({ width: 700, height: 350, crop: 'fill', format: 'jpg' }, image)} />
                    )}
                    <CardContent classes={{ root: !hasImage && classes.cardContentWithoutImage }}>
                      <Typography component="div" classes={{ root: classes.markdown }}>
                        <ReactMarkdown>{description}</ReactMarkdown>
                      </Typography>
                    </CardContent>
                  </Box>
                  {Boolean(pkg) && <CardActions classes={{ root: classes.cardActions }}>
                    {enabled ? (
                      <Button component={Link} href={href}>
                        {buttonTitle}
                      </Button>
                    ) : (
                      <Button onClick={() => setModalVisible(packageName)}>{buttonTitle}</Button>
                    )}
                  </CardActions>}
                </FlatCard>
              </Grid>
            )
          })}
        </Grid>

        <Box m={5} mb={4}>
          <Divider variant="middle" />
        </Box>

        <Box mb={2} ml={2}>
          <Typography variant="h3">Welcome{userName ? `, ${userName}` : ''}</Typography>
        </Box>

        <Box pb={8}>
          <Grid spacing={3} container>
            {messages.map(({ _id, title, description, image }) => {
              const hasImage = exists(image)
              return (
                <Grid key={_id} item xs={12}>
                  <FlatCard>
                    <CardContent>
                      <Grid container spacing={2}>
                        {hasImage && (
                          <Grid item>
                            <Image options={{ width: 231, height: 200, crop: 'fill', format: 'jpg' }} image={image} />
                          </Grid>
                        )}
                        <Grid item>
                          <Typography variant="h4" gutterBottom>
                            {title}
                          </Typography>
                          <Typography component="div">
                            <ReactMarkdown>{description}</ReactMarkdown>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {/* This prevents extra padding when CardContent is the last child. */}
                    <div />
                  </FlatCard>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </>
    )
  }
)
