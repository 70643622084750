const yup = require('yup')
const mobileUserTypes = require('../mobile-user-types')
const { requiredFields, labelify } = require('./helpers')
const levelsOfCare = require('../levels-of-care')

const testMonthlyPercentDistribution = async (eventLabels = []) => {
  if (!eventLabels.length) return true
  const monthlyEventsDistribution = eventLabels.reduce(
    (acc, { monthTargetPercent }) => {
      acc['percent'] += Number(monthTargetPercent) || 0
      acc['count'] += 1
      return acc
    },
    { percent: 0, count: 0 }
  )
  const { percent = 0, count = 0 } = monthlyEventsDistribution
  return count > 0 ? percent === 100 : true
}

const eventTypeSchema = yup.object().shape({
  _id: yup.string(),
  label: yup.string().default(''),
  color: yup.string().ensure().default('#C1E2EC'),
  minEventsPerWeek: yup.number().positive().label('Minimum events per week').default(5),
  monthTargetPercent: yup.number().min(0).max(100).label('Target percent').default(100),
})

const eventTypesResponseSchema = yup.array().of(eventTypeSchema).default([])

const shape = labelify({
  name: yup.string().default(''),
  details: yup.string().default(''),
  resort: yup.mixed(),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  displayOnFeedScreen: yup.boolean().default(true),
  icalImport: yup.object().shape({
    url: yup
      .string()
      .nullable()
      .transform((v) => v || null),
    active: yup.boolean().default(false),
  }),
  eventTypes: yup.array().of(eventTypeSchema).default([]),
  levelsOfCare: yup.array().of(yup.string().oneOf(levelsOfCare).nullable()).default([]),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
})

const calendarSchemaPartial = yup.object().shape(shape)
const calendarSchema = calendarSchemaPartial.shape(requiredFields(['name'])(shape))

const calendarResponseSchema = calendarSchema.shape({
  icalImport: yup.object().shape({
    url: yup.string().ensure(),
    active: yup.boolean().default(false),
    lastImportedAt: yup.date().nullable(),
    lastImportStatus: yup.string().nullable(),
    lastImportMessage: yup.string().nullable(),
  }),
  eventTypes: yup.array().of(eventTypeSchema).test('is-total-distribution-100', 'Invalid monthly distribution', testMonthlyPercentDistribution).default([]),
})

const calendarFormSchema = calendarResponseSchema

module.exports = { calendarSchema, calendarSchemaPartial, calendarResponseSchema, calendarFormSchema, eventTypeSchema, eventTypesResponseSchema }
