import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Snow Report saved', type: 'success' }),
  route.toSnowReports,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Snow Report deleted', type: 'success' }),
  findForList,
]
