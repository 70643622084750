// Quiet React context warnings in development.
if (import.meta.env.DEV) {
  // These are deprecation warnings, and they do matter for future development, but they are very noisy day-to-day.
  const SUPPRESSED_WARNINGS = ['legacy contextTypes API', 'legacy childContextTypes API', 'defaultProps will be removed', 'findDOMNode is deprecated']

  const filterSuppressed = (logger) => (message, ...args) => {
    if (!SUPPRESSED_WARNINGS.some((entry) => message.includes(entry))) {
      logger(message, ...args)
    }
  }

  console.warn = filterSuppressed(console.warn)
  console.error = filterSuppressed(console.error)
}

import React, { useEffect } from 'react'
import { sequences, state } from 'cerebral'
import { connect } from '@cerebral/react'
import Layout from './layout/Layout'
import { Helmet } from 'react-helmet'
import { create } from 'jss'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import jssPreset from '@mui/styles/jssPreset'
import StylesProvider from '@mui/styles/StylesProvider'
import Router from './views/Router'
import { createMUITheme } from '../theme'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { Settings, Info } from 'luxon'

Settings.defaultWeekSettings = {
  firstDay: 7,
  minimalDays: Info.getMinimumDaysInFirstWeek(),
  weekend: Info.getWeekendWeekdays(),
}

const jss = create({ plugins: jssPreset().plugins })

const defaultTitle = 'Manage - Alpine Media Technology'
const titleTemplate = `%s - ${defaultTitle}`

const theme = createMUITheme({ responsiveFontSizes: true })

const App = connect(
  {
    checkAccount: sequences`account.reauthenticate`,
    currentUser: state`account.currentUser`,
    getInfo: sequences`info.getInfo`,
    requestingCount: state`locationRouteRequests.requestingCount`,
    removeInvitationParams: sequences`account.removeInvitationParams`,
    invitationParams: state`account.invitationParams`,
  },
  ({ checkAccount, currentUser, getInfo, requestingCount, removeInvitationParams, invitationParams }) => {
    useEffect(() => {
      checkAccount()
    }, [])

    useEffect(() => {
      if (currentUser?.canAccessCommandCenter) {
        getInfo()
        if (invitationParams) {
          removeInvitationParams()
        }
      }
    }, [currentUser?.canAccessCommandCenter])

    const countPrefix = requestingCount ? `(${requestingCount}) ` : ''

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-US">
            <StylesProvider jss={jss}>
              <Helmet titleTemplate={`${countPrefix}${titleTemplate}`} defaultTitle={`${countPrefix}${defaultTitle}`} defer={false} />
              <Layout>
                <Router />
              </Layout>
            </StylesProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
)

export default App
