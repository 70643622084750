import React from 'react'
import Pagination from '@mui/material/Pagination'

const ListPagination = ({ list = {}, find }) => {
  return list.total > list.limit && (
    <Pagination
      count={Math.ceil(list.total / list.limit)}
      page={list.skip / list.limit + 1}
      onChange={(__, value) => find({ query: { $skip: (value - 1) * list.limit } })}
    />
  )
}

export default ListPagination
