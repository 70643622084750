import { moduleState, props } from 'cerebral'
import { set } from 'cerebral/factories'
import { compose, path, pluck } from 'ramda'
import format from '../../../shared/format'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { find as profilesFind } from '../profiles/sequences'
import { loadProfilesSequence } from '../profiles/factories'
import { sequences as route } from '../route'
import * as actions from './actions'

const loadProfiles = loadProfilesSequence(compose(pluck('recipientProfile'), path(['props', 'entities', 'appreciationAwards'])), 'profileIds')
const loadUsers = loadProfilesSequence(compose(pluck('authorUser'), path(['props', 'entities', 'appreciationAwards'])))

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
  loadProfiles,
  loadUsers,
]

export const get = [
  //
  actions.get,
  saveEntities(),
  loadProfiles,
  loadUsers,
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Award saved', type: 'success' }),
  route.toAwards,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Award deleted', type: 'success' }),
  find,
]

export const findRecipientUsers = [
  profilesFind,
  ({ props, store }) => {
    const {
      entities: { profiles = [] } = {},
    } = props
    const options = profiles.map(({ _id, name }) => ({ label: format.name(name), value: _id }))
    store.set(moduleState`recipientOptions`, options)
  },
]

export const toggleReviewed = [
  //
  actions.toggleReviewed,
  saveEntities(),
  findForList,
]

export const toggleDeleted = [
  //
  actions.toggleDeleted,
  saveEntities(),
  findForList,
]
