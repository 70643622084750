import { connect } from '@cerebral/react'
import { Box, Grid, Link, Typography, MenuItem, Button, useTheme } from '@mui/material'
import { sequences, state, props } from 'cerebral'
import { pick, keys, isEmpty } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { capitalize } from 'underscore.string'
import { useToggleState } from '../../../lib/util/hooks'
import ConfirmDialog from '../../controls/ConfirmDialog'
import ActionHeader from '../../elements/ActionHeader'
import { routePaths } from '../../../modules/route'
import CardList, { CardListItem } from '../../blocks/CardList'
import { entityFromState } from '../../../modules/computed'
import StatusText from '../../elements/StatusText'
import Address from '../../elements/Address'

const Card = connect(
  {
    remove: sequences`contactInfos.remove`,
    item: entityFromState('contactInfos', props`id`),
    toggleContactField: sequences`contactInfos.toggleContactField`,
    organization: state`account.organization`,
  },

  ({ item, remove, toggleContactField, organization: { mobileCenterAction = '' } }) => {
    const { _id: id, title, isEmergencyContact, isPrimaryContact } = item
    const [deleting, openDeleting, closeDeleting] = useToggleState(false)
    const theme = useTheme()
    const confirmDelete = useCallback(() => openDeleting({ id }), [id])
    const onConfirm = useCallback(() => remove({ id }))

    const displayMobileHeaderField = mobileCenterAction === 'SkiPatrol'

    const actions = [
      <MenuItem key="edit" component={Link} href={routePaths.toContactInfo({ id })}>
        Edit contact
      </MenuItem>,

      <MenuItem key="emergencyContact" onClick={() => toggleContactField({ id, field: 'isEmergencyContact' })}>
        {isEmergencyContact ? 'Remove' : 'Set'} as emergency contact
      </MenuItem>,

      displayMobileHeaderField ? (
        <MenuItem key="primaryContact" onClick={() => toggleContactField({ id, field: 'isPrimaryContact' })}>
          {isPrimaryContact ? 'Remove from' : 'Show in'} mobile app header
        </MenuItem>
      ) : null,

      <MenuItem key="delete" onClick={confirmDelete}>
        Delete contact
      </MenuItem>,
    ].filter(Boolean)

    const status = (
      <>
        {isPrimaryContact && (
          <StatusText component="span" variant="caption" color="success" style={{ marginRight: theme.spacing(1) }}>
            Primary Contact
          </StatusText>
        )}

        {isEmergencyContact && (
          <StatusText component="span" variant="caption" color="success">
            Emergency Contact
          </StatusText>
        )}
      </>
    )

    return (
      <>
        <ConfirmDialog open={deleting} title="Delete contact info" onConfirm={onConfirm} onClose={closeDeleting} confirmText="Delete">
          Are you sure you want to delete <strong>{title}</strong>?
        </ConfirmDialog>

        <CardListItem status={status} item={item} title={title} body={''} href={routePaths.toContactInfo({ id })} actions={actions} dragSort />
      </>
    )
  }
)

const ContactSettings = connect(
  {
    organization: state`account.organization`,
    saveSettings: sequences`account.saveSettings`,
    timezone: state`account.currentUser.timezone`,
    updateOrder: sequences`contactInfos.updateOrder`,
  },

  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['address', 'logo'], values) }),
  }),

  ({ organization = {}, updateOrder }) => {
    const { _id: id, address = {}, mobileIntro, socialMedia } = organization

    const socialMediaSites = useMemo(
      () =>
        keys(socialMedia)
          .map((site) => (socialMedia[site] ? capitalize(site) : ''))
          .filter(Boolean)
          .join(', '),
      [socialMedia]
    )
    const isAddressEmpty = useMemo(() => isEmpty(Object.values(address).filter(Boolean)), [address])

    return (
      id && (
        <>
          <Box>
            <ActionHeader title="Contact Info" section="Organization" />
            <Grid spacing={4} container>
              <Grid md={4} xs={12} item>
                <Address address={address} />
                <Link href={routePaths.toSettings({ redirectTo: 'contact-info' })} rel="noopener noreferrer">
                  {isAddressEmpty ? 'Add' : 'Edit'} Address
                </Link>
              </Grid>

              <Grid md={6} xs={12} item>
                <Typography variant="body2">{mobileIntro.contact}</Typography>
                {socialMediaSites ? (
                  <Box my={1}>
                    <Typography variant="body2" spacing={2}>
                      {socialMediaSites}
                    </Typography>
                  </Box>
                ) : null}
                <Link href={routePaths.toContactSettings()} rel="noopener noreferrer">
                  {isEmpty(socialMediaSites) ? 'Add Social Media Profiles' : 'Edit Contact Settings'}
                </Link>
              </Grid>

              <Grid md={2} xs={12} item>
                <Button component={Link} href={routePaths.toContactInfo({ id: 'create' })} color="primary">
                  Add contact info
                </Button>
              </Grid>
            </Grid>

            <CardList type="contactInfo" title=" " itemComponent={Card} onDragSort={updateOrder} />
          </Box>
        </>
      )
    )
  }
)

export default ContactSettings
