import * as Sentry from '@sentry/browser'
import { moduleState, state } from 'cerebral'
import { DateTime } from 'luxon'
import pLimit from 'p-limit'
import { fromPairs, groupBy, prop, toPairs } from 'ramda'
import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'libraryItems' })

export const adjustAvailableUntil = ({ props: { values = {} }, get }) => {
  let { availableUntil } = values
  if (availableUntil) {
    const timezone = get(state`account.currentUser.timezone`)
    availableUntil = DateTime.fromISO(availableUntil, { zone: timezone })
    if (availableUntil.isValid) {
      return { values: { ...values, availableUntil } }
    }
  }
  return { values: { ...values, availableUntil: null } }
}

export const collectIds = ({ props: { entities: { libraryItems = [] } = {} }, store }) => {
  const now = new Date()
  toPairs(groupBy(prop('provider'), libraryItems)).map(([provider, items]) => {
    store.merge(
      moduleState`providerContent.${provider}`,
      fromPairs(items.map(({ providerId, availableUntil }) => [providerId, !availableUntil || availableUntil > now]))
    )
  })
}

export const unsetCheck = ({ props, store }) => {
  const { provider, providerId } = props
  store.set(moduleState`providerContent.${provider}.${providerId}`, false)
}

// Prevent looking up too many items from the server at once.
const checkConcurrencyLimit = pLimit(5)

export const check = async ({ props: { provider, id }, store, get, libraryItemsService }) => {
  const storePath = moduleState`providerContent.${provider}.${id}`
  let found = get(storePath)
  // Check the server if we don't already know whether it's found.
  if (!found && found !== false) {
    try {
      const { total } = await checkConcurrencyLimit(() => libraryItemsService.find({ provider, providerId: id, $limit: 0 }))
      found = Boolean(total)
      // Update the store so we don't check again.
      store.set(storePath, found)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return { found: Boolean(found) }
}
