const yup = require('yup')
const { fromPairs, intersection, union } = require('ramda')
const { labelify } = require('./helpers')
const timeField = require('./fields/time-field')
const { daysOfWeekKeys } = require('./fields/days-of-week')

const hoursShape = ({ nullable }) =>
  fromPairs(
    daysOfWeekKeys.map((day) => [
      day,
      yup
        .object()
        .shape({ open: timeField({ nullable }), close: timeField({ nullable }) })
        .default(undefined),
    ])
  )

const statuses = [null, 'none', 'hold', 'race-in-progress', 'pass-required']

const conditions = [null, 'none', 'snow-making', 'grooming', 'grooming-morning', 'grooming-night', 'glades', 'moguls', 'night-snow-making']

const difficulties = [
  //
  null,
  'none',
  'easy',
  'terrain-park-easy',
  'easier',
  'intermediate',
  'advanced-intermediate',
  'expert',
  'extreme-terrain',
  'special-terrain',
  'terrain-park',
  'uphill-access',
]

const shape = labelify({
  name: yup.string().nullable(),
  override: yup.object().shape({
    open: yup.boolean().nullable(),
    status: yup
      .string()
      .oneOf(statuses)
      .nullable()
      .transform((v) => v || null),
    statusText: yup
      .string()
      .nullable()
      .transform((v) => v || null),
    conditions: yup
      .string()
      .oneOf(conditions)
      .nullable()
      .transform((v) => v || null),
    conditionsText: yup
      .string()
      .nullable()
      .transform((v) => v || null),
    difficulty: yup
      .string()
      .oneOf(difficulties)
      .nullable()
      .transform((v) => v || null),
  }),
  difficulty: yup.string().oneOf(difficulties).nullable(),
  mountainArea: yup
    .mixed()
    .nullable()
    .transform((v) => v || null),
  hours: yup
    .object()
    .shape(hoursShape({ nullable: true }))
    .default(undefined),
  tags: yup.array().of(yup.string()).nullable(),
  manageStatusOpen: yup.boolean().nullable(),
  manageStatusClose: yup.boolean().nullable(),
})

const trailSchemaPartial = yup.object().shape(shape)
const trailSchema = trailSchemaPartial

const trailScheduleSchema = yup.object().shape({
  start: timeField(),
  end: timeField(),
  rrule: yup.object().shape({
    freq: yup
      .number()
      .transform(() => 2)
      .default(2),
    interval: yup.number().default(1),
    byweekday: yup.array().default([]),
  }),
})

const trailResponseSchema = trailSchema.shape({
  open: yup.boolean().nullable(),
  status: yup.string().oneOf(statuses).nullable(),
  statusText: yup.string().nullable(),
  override: shape.override.default({}),
  hours: yup.object().shape(hoursShape({ nullable: false })),
  manageStatusOpen: shape.manageStatusOpen.default(false),
  manageStatusClose: shape.manageStatusClose.default(false),
  mountainArea: shape.mountainArea,
})

const trailFormSchema = trailResponseSchema.shape({
  name: yup.string().label('Title').required(),
  tags: yup
    .array()
    .of(yup.string())
    .transform((v) => v.filter(Boolean))
    .default([]),
  schedules: yup
    .array()
    .of(trailScheduleSchema)
    .default([])
    .test({
      name: 'schedule-conflict',
      message: 'Cannot set multiple hours for the same day.',
      test: (v = []) => {
        let daysScheduled = []
        let daysConflicted = []
        for (const schedule of v) {
          const overlap = intersection(daysScheduled, schedule.rrule.byweekday)
          if (overlap.length > 0) {
            daysConflicted = union(daysConflicted, overlap)
          }
          daysScheduled = union(daysScheduled, schedule.rrule.byweekday)
        }

        return daysConflicted.length === 0
      },
    }),
  override: yup.object().shape({
    open: yup.boolean().nullable(),
    status: yup.string().oneOf(statuses.concat('')).ensure(),
    statusText: yup
      .string()
      .nullable()
      .transform((v) => v || null),
    conditions: yup.string().oneOf(conditions.concat('')).ensure(),
    conditionsText: yup
      .string()
      .nullable()
      .transform((v) => v || null),
    difficulty: yup.string().oneOf(difficulties.concat('')).ensure(),
  }),
  mountainArea: yup.string().ensure(),
})

module.exports = { trailSchema, trailSchemaPartial, trailResponseSchema, trailFormSchema, trailScheduleSchema }
