import { crudActions } from '../factories'
import { moduleState } from 'cerebral'

export const { find, setList, clearList, get } = crudActions({ name: 'surveyResponses' })

// Currently there is only ever a single suvey. We will refactor to support more.
export const setSurvey = ({ props: { entities: { surveys, ...entities } }, store }) => {
  const [ survey ] = surveys
  store.set(moduleState`survey`, survey)
  return { entities }
}
