import { moduleState, state } from 'cerebral'
import { DateTime } from 'luxon'
import { isNull, omitBy } from 'ramda-adjunct'
import { isNil } from 'ramda'
import { crudActions } from '../factories'

const omitNull = omitBy(isNull)

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'statusUpdates' })

export const useTimeRange = ({ props, get }) => {
  if (!props?.query?.createdAt) {
    const timezone = get(state`account.currentUser.timezone`)
    const getDate = (date) => date && DateTime.fromISO(date, { zone: timezone }).setZone('utc', { keepLocalTime: true }).startOf('day')
    const { query = {} } = props
    const { startDate, endDate } = get(moduleState`dashboard`) || {}
    query.createdAt = startDate || endDate ? omitNull({ $gte: getDate(startDate)?.startOf('day'), $lte: getDate(endDate)?.endOf('day') }) : undefined
    delete query._id
    return { query }
  }
}

export const dashboardSetTimeRange = ({ props: { selectedFilter, days, offset }, store, get }) => {
  const timezone = get(state`account.currentUser.timezone`)
  store.set(moduleState`dashboard.selectedFilter`, selectedFilter)
  const now = DateTime.local()
    .setZone(timezone)
    .plus({ days: offset || 0 })
    .setZone('utc', { keepLocalTime: true })
    .startOf('day')

  const hasValidDays = !isNil(days)
  const startDate = hasValidDays ? now.minus({ days }) : null
  const endDate = hasValidDays ? now : null

  store.set(moduleState`dashboard.endDate`, endDate && endDate.toISODate())
  store.set(moduleState`dashboard.startDate`, startDate && startDate.toISODate())
  // FIXME: This is a messy approach. It stashes a query onto the route to cause the list to redraw.
  // It also effectively overrides/duplicates the useTimeRange logic above.
  store.set(state`route.params.createdAt`, startDate || endDate ? omitNull({ $gte: startDate?.startOf('day'), $lte: endDate?.endOf('day') }) : { $ne: null })
}
