import { isNilOrEmpty, omitBy } from 'ramda-adjunct'
import apiClient from '../../lib/util/api-client'

const service = apiClient.service('uploads')

const omitEmpty = omitBy(isNilOrEmpty)

export default () => ({
  state: {},
  sequences: {
    signUpload: [
      ({ props: { upload_preset, public_id } }) => service.create(omitEmpty({ upload_preset, public_id })),
    ]
  },
})
