import React from 'react'
import PropTypes from 'prop-types'
import { List } from '@mui/material'
import NavItem from './NavItem'
import { flatten, find } from 'ramda'
import { routeAccess } from '../../modules/route'

const NavSection = ({ packages, items, depth = 0 }) => {
  return (
    <List disablePadding>
      {items.map((item) => {
        let enabled = false
        if (item.items) {
          const routes = flatten(item.items.map(({ route, match }) => route || match))
          const route = find((route) => routeAccess[route](packages), routes)
          enabled = Boolean(route)
        } else {
          enabled = routeAccess[item.route](packages)
        }

        return (
          enabled && (
            <NavItem depth={depth} key={item.title} {...item}>
              {item.items && <NavSection packages={packages} items={item.items} depth={depth + 1} />}
            </NavItem>
          )
        )
      })}
    </List>
  )
}

NavSection.propTypes = {
  packages: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  depth: PropTypes.number,
}

export default NavSection
