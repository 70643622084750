const yup = require('yup')
const { fromPairs, intersection, union } = require('ramda')
const { labelify } = require('./helpers')
const timeField = require('./fields/time-field')
const { daysOfWeekKeys } = require('./fields/days-of-week')

const hoursShape = ({ nullable }) =>
  fromPairs(daysOfWeekKeys.map((day) => [day, yup.object().shape({ open: timeField({ nullable }), close: timeField({ nullable }) }).default(undefined)]))

const shape = labelify({
  name: yup.string().nullable(),
  size: yup.string().oneOf(['single', 'double', 'triple-chair', 'quad-chair', '6-chair', 'cabriolet']).nullable(),
  mountainArea: yup.mixed().nullable().transform((v) => v || null),
  bicyclesPermitted: yup.boolean().nullable(),
  status: yup.string().nullable(),
  statusText: yup.string().nullable(),
  statusTextOverride: yup.string().nullable(),
  statusOverride: yup.string().nullable(),
  statusOverrideDescription: yup.string().nullable(),
  tags: yup.array().of(yup.string()).nullable(),
  override: yup.object().shape({
    waitMinutes: yup.number().min(0).nullable(),
  }).default(undefined),
  hours: yup.object().shape(hoursShape({ nullable: true })).default(undefined),
  manageStatusOpen: yup.boolean().nullable(),
  manageStatusClose: yup.boolean().nullable(),
})

const liftSchemaPartial = yup.object().shape(shape)
const liftSchema = liftSchemaPartial

const liftScheduleSchema = yup.object().shape({
  start: timeField(),
  end: timeField(),
  rrule: yup.object().shape({
    freq: yup
      .number()
      .transform(() => 2)
      .default(2),
    interval: yup.number().default(1),
    byweekday: yup.array().default([]),
  }),
})

const liftResponseSchema = liftSchema.shape({
  size: shape.size.required().ensure(),
  mountainArea: shape.mountainArea,
  bicyclesPermitted: shape.bicyclesPermitted.default(false),
  statusText: shape.statusText.ensure(),
  statusTextOverride: shape.statusTextOverride.ensure(),
  status: shape.status.ensure(),
  statusOverride: shape.statusOverride.ensure(),
  statusOverrideDescription: shape.statusOverrideDescription.ensure(),
  override: shape.override.default({}),
  hours: yup.object().shape(hoursShape({ nullable: false })),
  manageStatusOpen: shape.manageStatusOpen.default(false),
  manageStatusClose: shape.manageStatusClose.default(false),
})

const liftFormSchema = liftResponseSchema.shape({
  mountainArea: yup.string().ensure(),
  tags: yup.array().of(yup.string()).transform((v) => v.filter(Boolean)).default([]),
  schedules: yup
    .array()
    .of(liftScheduleSchema)
    .default([])
    .test({
      name: 'schedule-conflict',
      message: 'Cannot set multiple hours for the same day.',
      test: (v = []) => {
        let daysScheduled = []
        let daysConflicted = []
        for (const schedule of v) {
          const overlap = intersection(daysScheduled, schedule.rrule.byweekday)
          if (overlap.length > 0) {
            daysConflicted = union(daysConflicted, overlap)
          }
          daysScheduled = union(daysScheduled, schedule.rrule.byweekday)
        }

        return daysConflicted.length === 0
      },
    }),
})

module.exports = { liftSchemaPartial, liftSchema, liftResponseSchema, liftFormSchema, liftScheduleSchema }
