import { DateTime } from 'luxon'
import { moduleState, state } from 'cerebral'
import { omit, pick } from 'ramda'
import { crudActions } from '../factories'
import format from '../../../shared/format'

const timeParts = pick(['hour', 'minute', 'second'])

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'appointments' })

export const togglePublished = async ({ props: { id }, appointmentsService: service, get }) => {
  if (id) {
    const appointment = get(moduleState`entities.${id}`)
    const data = await service.patch(id, { ...appointment, published: !appointment?.published })
    return { entities: { appointments: [data] } }
  }
}

export const mapCalendarFields = ({ props: { entities }, get }) => {
  const timezone = get(state`account.currentUser.timezone`)
  const profiles = get(state`profiles.entities`)
  const appointmentLabelsMap = get(state`account.appointmentLabelsMap`)

  let { appointments } = entities

  appointments = appointments.map((appointment) => {
    let { _id: id, startAt, endAt, title, profile, needsTransportation, type, location } = appointment

    const name = format.name(profiles[profile]?.name)
    if (name) {
      title = `${name} - ${title}`
    }
    if (location) {
      title += `. ${location}`
    }
    startAt = DateTime.fromJSDate(startAt).setZone(timezone)
    endAt = DateTime.fromJSDate(endAt).setZone(timezone)

    if (appointmentLabelsMap[type]) {
      const label = appointmentLabelsMap[type]
      appointment.color = label.color
      appointment.labelColor = label.color
      appointment.labelTextColor = label.textColor
      appointment.shortCode = label.shortCode
    }

    const start = startAt.toISO()
    const end = endAt.toISO()

    return { ...omit(['_id', 'startAt', 'endAt'], appointment), id, startISO: start, start, end, title, needsTransportation, featured: needsTransportation }
  })
  return { appointments }
}

export const changeTime = async ({ props: { _id, originStart, delta }, get, appointmentsService }) => {
  const timezone = get(state`account.currentUser.timezone`)
  const appointment = get(state`appointments.entities.${_id}`)

  originStart = DateTime.fromMillis(originStart, { zone: timezone })

  let { startAt, endAt } = appointment

  const originEnd = originStart.set(timeParts(DateTime.fromJSDate(endAt, { zone: timezone })))

  startAt = originStart.plus(delta)
  endAt = originEnd.plus(delta)

  const result = await appointmentsService.patch(_id, { ...appointment, startAt, endAt })
  return { values: result }
}
