import { connect } from '@cerebral/react'
import React from 'react'
import { Box, Link, MenuItem, Typography } from '@mui/material'
import { sequences, state } from 'cerebral'
import { omit } from 'ramda'
import { routePaths } from '../../modules/route'
import DropDown from '../controls/DropDown'
import { ArrowDropDown } from '@mui/icons-material'

const TitleForDropdown = connect(
  {
    calendar: state`calendars.currentCalendar`,
    location: state`mealLocations.currentLocation`,
    eventType: state`route.params.eventType`,
  },
  ({ showCaret = true, calendar, location, style, onClick, eventType, ...rest }) => {
    const isEvent = eventType === 'Event'
    const eventTitle = calendar ? calendar?.name : 'All Events'
    const mealsTitle = location ? location?.name : 'All Meals'

    return (
      <Box display="flex" alignItems="center" style={style} onClick={onClick}>
        <Typography variant="h2" {...omit(['get', 'reaction'], rest)}>
          {isEvent ? eventTitle : mealsTitle}
        </Typography>
        {showCaret && <ArrowDropDown fontSize="large" />}
      </Box>
    )
  }
)

const DropDownTitle = connect(
  {
    calendars: state`calendars.list.data`,
    mealLocations: state`mealLocations.list.data`,
    changeCalendar: sequences`calendars.changeCalendar`,
    changeMealLocation: sequences`mealLocations.changeMealLocation`,
    eventType: state`route.params.eventType`,
  },
  ({ calendars, mealLocations, eventType, changeCalendar, changeMealLocation }) => {
    const isEvent = eventType === 'Event'
    const currentlyOnCalendars = isEvent && calendars.length > 0

    const handleChangeAction = currentlyOnCalendars ? changeCalendar : changeMealLocation
    const items = currentlyOnCalendars ? calendars : mealLocations.length > 0 ? mealLocations : []
    const itemPath = routePaths[isEvent ? 'toCalendar' : 'toMealLocation']

    return (
      <DropDown anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} component={TitleForDropdown}>
        {[{ name: `All ${isEvent ? 'Events' : 'Meals'}` }, ...items].map((item) => (
          <MenuItem key={item?.name} onClick={() => handleChangeAction(isEvent ? { calendar: item } : { location: item })} style={{ minWidth: 100 }}>
            {item?.name}
          </MenuItem>
        ))}

        <MenuItem key="create-button" style={{ minWidth: 100 }}>
          <Link href={itemPath({ id: 'create' })} size="medium" color="primary">
            Add {isEvent ? 'Calendar' : 'Location'}
          </Link>
        </MenuItem>
      </DropDown>
    )
  }
)

export default DropDownTitle
