import { when } from 'cerebral/factories'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'
import * as actions from './actions'
import { moduleState } from 'cerebral'

export const find = [
  //
  actions.find,
  actions.expandHoursToSchedule,
  saveEntities(),
  actions.collectTags,
]

export const findForList = [
  //
  ({ props }) => ({ useRouteParams: props.useRouteParams !== false }),
  actions.find,
  actions.expandHoursToSchedule,
  actions.setList,
  saveEntities(),
  actions.collectTags,
]

export const get = [
  //
  actions.get,
  actions.expandHoursToSchedule,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  saveEntities(),
]

export const saveAndReturn = [
  //
  actions.setHoursFromSchedule,
  actions.save,
  saveEntities(),
  showFeedback({ title: 'Trail saved', type: 'success' }),
  route.toTrails,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Trail cleared', type: 'success' }),
  find,
]

export const clearAllOverrides = [
  //
  actions.clearAllOverrides,
  saveEntities(),
  showFeedback({ title: 'Overrides cleared', type: 'success' }),
]

export const checkTags = [
  when(moduleState`allTags`, (allTags) => allTags?.length === 0),
  {
    true: find,
    false: [],
  },
]
