import { crudActions } from '../factories'
import { moduleState } from 'cerebral'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'notifications' })

export const getRecipientCount = async ({ props: { audience }, store, recipientsService }) => {
  const { count } = await recipientsService.find({ audience })
  store.set(moduleState`recipientCount`, count)
  return { count }
}
