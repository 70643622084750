const yup = require('yup')
const { labelify } = require('./helpers')
const mobileUserTypes = require('../mobile-user-types')
const levelsOfCare = require('../levels-of-care')
const { DateTime } = require('luxon')

const phoneMatch = /[1-9]\d{2}-\d{3}-\d{4}/

const shape = labelify({
  phone: yup
    .string()
    .when(['send', 'email'], {
      is: (send, email) => Boolean(send && !email),
      then: (s) => s.matches(phoneMatch, 'This phone number is not valid.').required('Either email or phone is required'),
    })
    .default(''),
  name: yup.object().shape({
    first: yup.string().required().default('').label('First name'),
    last: yup.string().required().default('').label('Last name'),
  }),
  email: yup
    .string()
    .email()
    .when(['send', 'phone'], { is: (send, phone) => Boolean(send && !phone), then: (s) => s.required('Either email or phone is required') })
    .default(''),
  profile: yup.mixed(),
  userType: yup.string().required().oneOf(mobileUserTypes).default(mobileUserTypes[0]),
  associatedWith: yup.string().default(''),
  trackAttendance: yup.boolean().nullable(),
  photosPermitted: yup.boolean().nullable().default(true),
  send: yup.boolean().default(false),
  product: yup.string(),
  address: yup.string().default(''),
  birthdate: yup
    .date()
    .max(new Date())
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  levelOfCare: yup
    .string()
    .ensure()
    .oneOf(levelsOfCare, `Level of care must be one of: ${levelsOfCare.filter(Boolean).join(', ')}`),
})

const invitationSchema = yup.object().shape(shape, [['email', 'phone']]) // This extra array parameter avoids circular dependency in the validation. See https://github.com/jquense/yup/issues/720#issuecomment-564591045

const invitationResponseSchema = invitationSchema.shape({
  expiredAt: yup.date().nullable(),
  invitedAt: yup.date().nullable(),
  inviteAcceptedAt: yup.date().nullable(),
})

const invitationImportSchema = invitationSchema.shape({
  birthdate: yup
    .date()
    .max(new Date(), ({ value }) => `Invalid birthdate: '${DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_FULL)}'.`)
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  levelOfCare: yup
    .string()
    .ensure()
    .oneOf(levelsOfCare, ({ value }) => `Invalid level of care: '${value}'. Must be one of: ${levelsOfCare.filter(Boolean).join(', ')}`),
})

module.exports = { invitationSchema, invitationImportSchema, invitationResponseSchema }
