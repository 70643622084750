const { DateTime } = require('luxon')
const yup = require('yup')
const { toLower } = require('ramda')

const timeField = ({ nullable = false } = {}) => {
  const field = yup
    .string()
    .max(7)
    .meta({ maxLength: 7 })
    .test('validTime', 'must be a valid time', (time) => !time || DateTime.fromFormat(time, 'h:mma').isValid)
    .test('timeFormat', 'must be in hh:mma, such as 8:04am', (time) => !time || /^1?\d:\d{2}[ap]m$/.test(time))
    .transform((v) => v && toLower(v))

  return nullable ? field.nullable() : field.ensure()
}

module.exports = timeField
