const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.mixed(),
  trail: yup.mixed(),
  lift: yup.mixed(),
  status: yup.string().ensure().required(),
  statusText: yup.string().ensure().required(),
  isOverride: yup.boolean().default(false),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  updatedBy: yup.mixed(),
})

const statusUpdateSchemaPartial = yup.object().shape(shape)
const statusUpdateSchema = statusUpdateSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { statusUpdateSchema, statusUpdateSchemaPartial }
