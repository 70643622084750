import * as actions from './actions'
import { saveEntities } from '../factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'

export const find = [
  actions.find,
  saveEntities(),
]

export const findForList = [
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  actions.get,
  saveEntities(),
]
