const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { reservationSlotSchemaPartial } = require('./reservation-slot-schema')
const { omit } = require('ramda')

const shape = labelify({
  _id: yup.string(),
  reservationSlot: reservationSlotSchemaPartial,
  mobileUser: yup.mixed(),
  profile: yup.mixed(),
  count: yup.number().default(1),
  details: yup.string().max(400).meta({ maxLength: 400 }).ensure(),
  cancelledAt: yup.date().nullable(),
  expiredAt: yup.date(),
  checkIn: yup.object().shape({
    count: yup.number().nullable(),
    checkInAt: yup.date().nullable(),
  }),

  repeatSource: yup.string().nullable(),
  repeatType: yup.string().oneOf(['none', 'daily', 'weekly', 'monthly', 'yearly', 'custom']).default('none'),
  repeatRuleExceptDates: yup.array(yup.date()),
  rrule: yup.object().shape({
    freq: yup.number().default(1),
    interval: yup.number().default(1),
    byweekday: yup.array().default([]),
    bysetpos: yup.number().default(0),
  }),

  // This should use a User schema.
  createdBy: yup.object().shape({
    name: yup.object().shape({
      first: yup.string().default(''),
      last: yup.string().default(''),
    }),
  }).nullable(),
})

const reservationSchemaPartial = yup.object().shape(omit(['mobileUser'], shape))
const reservationSchema = yup.object().shape(shape).shape(requiredFields(['reservationSlot', 'count', 'profile'])(shape))

module.exports = { reservationSchema, reservationSchemaPartial }
