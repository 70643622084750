import React, { useState } from 'react'
import cc from 'classcat'
import PropTypes from 'prop-types'
import { Button, Link, Collapse, ListItem, Badge, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { routePaths, routeAccess } from '../../modules/route'
import { connect } from '@cerebral/react'
import { state } from 'cerebral'
import { omitConnectProps } from '../../lib/util/cerebral'
import { any, equals, pick } from 'ramda'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.grey['700'],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.grey['50'],
    },
  },
  buttonLeaf: {
    color: theme.palette.grey['700'],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.grey['50'],
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& path, & g': {
      fill: 'currentColor',
      fillOpacity: 1,
    },
  },
  title: {
    marginRight: 'auto',
    textDecoration: 'none',
  },
  active: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey['100'],
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
    },
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}))

const NavItem = connect(
  {
    currentRoute: state`route.key`,
    currentParams: state`route.params`,
    packages: state`account.packages`,
    labels: state`account.labels`,
  },

  ({
    title,
    labelCursor,
    route,
    params,
    match = [],
    currentRoute,
    currentParams,
    depth,
    children,
    icon: Icon,
    className,
    info: Info,
    packages,
    labels,
    badge,
    get,
    ...rest
  }) => {
    const classes = useStyles()
    title = labelCursor && labelCursor(labels) || title

    let badgeContent = null
    if (typeof badge === 'function') {
      const { count, color = 'primary' } = badge({ get })
      if (count) {
        badgeContent = (
          <Box mr={2}>
            <Badge badgeContent={count} color={color} />
          </Box>
        )
      }
    }

    let active = route === currentRoute || match.includes(currentRoute)
    if (active && params) {
      const filtered = pick(Object.keys(params), currentParams)
      active = equals(params, filtered)
    }

    const [open, setOpen] = useState(active)
    rest = omitConnectProps(rest)

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen)
    }

    let paddingLeft = 8

    if (depth > 0) {
      paddingLeft = 32 + 8 * depth
    }

    const style = { paddingLeft, textDecoration: 'none' }

    const permitted = any((name) => !routeAccess[name] || routeAccess[name](packages), [route].concat(match))
    if (!permitted) {
      return null
    }

    if (children) {
      return (
        <ListItem className={cc([classes.item, className])} disableGutters key={title} {...rest}>
          <Button variant="text" className={classes.button} onClick={handleToggle} style={style}>
            {Icon && <Icon className={classes.icon} height="20" width="20" />}
            <span className={classes.title}>{title}</span>
            {open ? null : badgeContent}
            {open ? <ExpandLessIcon size="small" color="inherit" /> : <ExpandMoreIcon size="small" color="inherit" />}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </ListItem>
      )
    }

    if (route === undefined) {
      return null
    }

    const href = routePaths[route] && routePaths[route](params)
    return (
      <ListItem className={cc([classes.itemLeaf, className])} disableGutters key={title} {...rest}>
        <Button
          variant="text"
          className={cc([classes.buttonLeaf, `depth-${depth}`, active && classes.active])}
          component={Link}
          style={style}
          href={href}
        >
          {Icon && <Icon className={classes.icon} height="20" width="20" />}
          <span className={classes.title}>{title}</span>
          {Info && <Info className={classes.info} />}
          {badgeContent}
        </Button>
      </ListItem>
    )
  }
)

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  route: PropTypes.string,
  match: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.any,
  info: PropTypes.any,
  title: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  open: false,
}

export default NavItem
