import { state } from 'cerebral'
import { moduleState } from 'cerebral'
import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'calendars' })

export const setCalendarDefault = ({ get, props: { values }, store }) => {
  const currentCalendar = get(state`calendars.currentCalendar`)
  if (!currentCalendar) {
    store.set(moduleState`currentCalendar`, null)
  }

  // set the current calendar to the currently updated values in state update
  else if (currentCalendar && values) {
    store.set(moduleState`currentCalendar`, values)
  }
}

export const changeCalendar = ({ props: { calendar = null }, store }) => {
  store.set(moduleState`currentCalendar`, calendar)
}
