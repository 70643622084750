const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const featureCollection = () => yup.array().of(yup.object().shape({
  id: yup.string(),
  type: yup.string(),
  properties: yup.mixed(),
  geometry: yup.mixed(),
}))

const shape = labelify({
  name: yup.string().default(''),
  description: yup.string().default(''),
  billingDescription: yup.string().default(''),
  notAcceptingRequests: yup.boolean().default(false),
  notAcceptingRequestsReason: yup.string().ensure(),
  isAutoDispatchEnabled: yup.boolean().default(false),
  isRideProductRequired: yup.boolean().default(true),
  displayOnlyWhenShiftsActive: yup.boolean().default(false),
  autoDispatchRequestLifetimeSeconds: yup.number().default(120),
  routeType: yup.string().oneOf(['loop', 'out-and-back', 'on-demand']).default('loop'),
  active: yup.bool(),
  segments: featureCollection(),
  stops: featureCollection(),
  mapColor: yup.string().ensure(),
  vehicleCount: yup.number().default(0),
  displayLocation: yup.string().oneOf(['signage', 'mobile', 'both']).default('both'),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  restrictToUserType: yup.string().ensure(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationRouteSchemaPartial = yup.object().shape(shape)
const locationRouteSchema = locationRouteSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationRouteSchema, locationRouteSchemaPartial }
