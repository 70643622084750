const yup = require('yup')
const { uploadSchema } = require('./cloudinary-schema')
const { labelify, requiredFields } = require('./helpers')
const mobileUserTypes = require('../mobile-user-types')
const scheduleDateTime = require('./fields/shedule-datetime')

const presentMobileUserTypes = mobileUserTypes.map((type) => `${type},present`)

const destSchema = () =>
  yup
    .string()
    .oneOf([
      //
      '',
      'Feed',
      'Map',
      'LiftsAndTrails',
      'Weather',
      'SnowConditions',
      'Events',
      'Menu',
      'Travel',
      'Alert',
      'Media',
      'UpdatesDetail',
      'ShuttleRequest',
    ])
    .default('')

const shape = yup.object().shape(
  labelify({
    internal: yup.boolean().default(false),
    enabled: yup.boolean().default(false),
    title: yup.string().max(30).meta({ maxLength: 30 }).ensure(),
    body: yup.string().max(240).meta({ maxLength: 240 }).ensure(),
    image: uploadSchema,
    audience: yup
      .string()
      .oneOf([
        //
        'present',
        'notpresent',
        ...mobileUserTypes,
        ...presentMobileUserTypes,
        'all',
        'selected',
        'automated',
      ])
      .default('all'),
    schedule: yup.string().oneOf(['immediate', 'scheduled', 'draft']).default('immediate'),
    scheduledAt: yup.date().nullable(),
    sentAt: yup.date().nullable(),
    recipientUsers: yup.array().default([]),
    destination: destSchema(),
    tapDestination: destSchema(), // This remains to display historical data.
    tapPayload: yup.mixed(),
    status: yup
      .string()
      .oneOf([
        //
        'scheduled',
        'pending',
        'draft',
        'sending',
        'delivering',
        'succeeded',
        'failed',
      ])
      .default('pending'),
  })
)

const required = requiredFields(['title', 'body'])

const notificationSchema = shape.shape(required(shape))
const nestedNotificationSchema = { notification: shape }

const notificationResponseSchema = notificationSchema.shape({
  createdAt: yup.date().nullable(),
  createdBy: yup.mixed(),
  intendedCount: yup.number().default(0),
  deliveredCount: yup.number().default(0),
  readCount: yup.number().default(0),
  upvotedCount: yup.number().default(0),
  recipientUsers: yup.array(),
})

const notificationFormSchema = notificationSchema.shape({
  scheduledAt: scheduleDateTime(),
})

module.exports = { notificationSchema, nestedNotificationSchema, notificationResponseSchema, notificationFormSchema }
