import { moduleState } from "cerebral"

const recoveryDelay = 2 * 60 * 1000

export const tryToRecover = ({ get }) => {
  const connected = get(moduleState`connected`)
  if (connected) {
    // NOTE: This uses localStorage directly, but if we persist our store we will want to use the store.
    const lastErrorRecovery = Number(localStorage.getItem('lastErrorRecovery') || 0)
    const now = Date.now()
    if (lastErrorRecovery < now - recoveryDelay) {
      localStorage.setItem('lastErrorRecovery', now.toString())
      setTimeout(() => window.location.reload(), 0)
    }
  }
}
