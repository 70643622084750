const { compose, isEmpty, pick, mapObjIndexed } = require('ramda')
const { humanize } = require('underscore.string')
const yup = require('yup')
const { toDateTime } = require('../utils')

// See https://objectpartners.com/2020/06/04/validating-optional-objects-with-yup/
yup.addMethod(yup.object, 'optional', function ({ isOptional = true, defaultValue = undefined } = {}) {
  return this.transform((value) => {
    if (!isOptional || !isEmpty(value) || value === null) return value
    return defaultValue
  }).default(defaultValue)
})

// This method is used to support AdapterLuxon from @mui/x-date-pickers, which expects dates to always be DateTimes.
// NOTE: Using luxon DateTimes depends on context.timezone.
yup.addMethod(yup.mixed, 'datetime', function () {
  return this.when('$timezone', (timezone, schema) => schema.transform((v) => v && toDateTime(v, timezone)).default(() => toDateTime(new Date(), timezone)))
})

const requiredFields = (fieldnames) =>
  compose(
    mapObjIndexed((v) => v.required()),
    pick(fieldnames)
  )

const labelify = (shape, options = {}) =>
  mapObjIndexed((definition, name) => {
    if (options.exclude && options.exclude.includes(name)) {
      return definition
    }

    return definition.label(humanize(name))
  }, shape)

module.exports = { requiredFields, labelify }
