import React, { useState, useEffect } from 'react'
import { connect } from '@cerebral/react'
import { Typography, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';
import { state, sequences } from 'cerebral'

const useStyles = makeStyles((theme) => ({
  Snackbar: {
    marginTop: theme.spacing(6),
  },
  Alert: {
    [theme.breakpoints.up('md')]: {
      minWidth: 500,
    },
  }
}))

const Feedback = connect(
  {
    currentMessage: state`feedback.currentMessage`,
    dismissCurrentMessage: sequences`feedback.dismissCurrentMessage`,
    navShown: state`route.navShown`,
  },

  (props) => ({ ...props, dismissCurrentMessage: () => props.dismissCurrentMessage() }),

  ({ currentMessage, dismissCurrentMessage, navShown }) => {
    const classes = useStyles()

    // Storing state so our transitions can reflect old data when it is gone.
    const [ title, setTitle ] = useState('')
    const [ body, setBody ] = useState('')
    const [ type, setType ] = useState('info')
    const [ dismissed, setDismissed ] = useState(false)

    useEffect(() => {
      if (currentMessage) {
        const { title, body, type } = currentMessage
        setTitle(title)
        setBody(body)
        setType(type)
        setDismissed(false)
      } else {
        setDismissed(true)
      }
    }, [currentMessage])

    return (
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} classes={{ anchorOriginTopCenter: navShown && classes.Snackbar }} open={!dismissed} autoHideDuration={10000} onClose={dismissCurrentMessage}>
        <Alert classes={{ root: classes.Alert }} icon={false} severity={type} onClose={dismissCurrentMessage}>
          {title && body ? <AlertTitle>{title}</AlertTitle> : null}
          <Typography>{body || title}</Typography>
        </Alert>
      </Snackbar>
    )
  }
)

export default Feedback
