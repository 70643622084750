const yup = require('yup')
const { labelify } = require('./helpers')
const { reservationScheduleSchemaPartial } = require('./reservation-schedule-schema')

const shape = labelify({
  _id: yup.string(),
  reservationSchedule: reservationScheduleSchemaPartial.nullable(),
  name: yup.string(),
  time: yup.date(),
  limit: yup.number(),
  limitRemaining: yup.number(),
})

const reservationSlotSchemaPartial = yup.object().shape(shape)
const reservationSlotSchema = reservationSlotSchemaPartial

module.exports = { reservationSlotSchema, reservationSlotSchemaPartial }
