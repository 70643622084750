import format from '../../../shared/format'

const { Intercom: ic, intercomSettings: settings } = window

const intercom = (user) => {
  if (ic) {
    const { email, name, registeredAt, intercomHash, company = {} } = user
    const createdUnixTimestamp = Math.floor(new Date(registeredAt).valueOf() / 1000)

    ic('update', {
      ...settings,
      email,
      name: format.name(name),
      created_at: createdUnixTimestamp,
      user_hash: intercomHash,
      company,
    })
  }
}

export default intercom
