import { crudActions } from '../factories'
import { moduleState } from 'cerebral'

export const { find, setList, clearList, get, save, remove, updateOrder } = crudActions({ name: 'albums' })

export const togglePublished = async ({ props: { id }, albumsService: service, get }) => {
  if (id) {
    const published = get(moduleState`entities.${id}.published`)
    const data = await service.patch(id, { published: !published })
    return { entities: { albums: [data] } }
  }
}
