const { DateTime } = require('luxon')
const numeral = require('numeral')
const template = require('lodash.template')
const { memoizeWith, identity } = require('ramda')

const name = ({ display, first, last } = {}) => (display || [first, last].filter(Boolean).join(' ') || '').trim()
const initials = ({ first, last } = {}) =>
  [first, last]
    .map((name) => name && name[0])
    .filter(Boolean)
    .join('')

const publishedName = (() => {
  const interpolate = /{{([\s\S]+?)}}/g
  const getCompiled = memoizeWith(identity, (str) => template(str, { interpolate }))

  return (format = '{{first_name}} {{last_name}}', name = {}) => {
    name = { first_name: name.first, last_name: name.last, first_initial: name.first?.[0], last_initial: name.last?.[0] }
    const compiled = getCompiled(format)
    return compiled(name)
  }
})()

const plural = (count, singular, { showCount } = {}) => `${showCount ? `${count} ` : ''}${count === 1 ? singular : `${singular}s`}`
const currency = (value) => numeral(value).format('$0,0.00')

const address = (address) => {
  if (typeof address === 'string') {
    return address
  }
  const { street1, street2, city, state, zip, country } = address || {}
  return [street1, street2, city, state, zip, country].filter(Boolean).join(', ')
}

const phoneMaskNoExtension = [
  {
    mask: '000-000-0000',
    lazy: false,
  },
]

const phoneMask = [
  ...phoneMaskNoExtension,
  {
    mask: '000-000-0000 x0[0000]',
    lazy: false,
  },
]

const birthdate = (birthdate) => DateTime.fromISO(birthdate, { zone: 'utc' }).toFormat('M/d')

const withoutFilenameIllegalCharacters = (filename = '', replacement = '') => filename.replace(/[/\\?%*:|"<>]/g, replacement)

const format = { name, initials, publishedName, plural, currency, phoneMask, phoneMaskNoExtension, address, birthdate, withoutFilenameIllegalCharacters }

module.exports = format
