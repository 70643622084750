import format from '../../../shared/format'
import brand from './brand'

export const load = () => {
  const script = document.createElement('script')
  script.src = 'https://cdn.pagesense.io/js/833690033/3a3582cc9a984aad90635f7eccd8448e.js'

  const existing = document.getElementsByTagName('script')[0]
  existing.parentNode.insertBefore(script, existing)

  const { zohoWidgetCode } = brand || {}
  if (zohoWidgetCode) {
    const scriptForBrand = document.createElement('script')

    scriptForBrand.type = 'text/javascript'
    scriptForBrand.id = 'zsiqchat'
    scriptForBrand.text = `var $zoho = $zoho || {};
    $zoho.salesiq = $zoho.salesiq || {widgetcode: "${zohoWidgetCode}", values:{}, ready:function(){} };
    var d = document;
    var s = d.createElement("script");
    s.type="text/javascript";
    s.id="zsiqscript";
    s.defer=true;
    s.src = "https://salesiq.zohopublic.com/widget";
    t=d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t); `

    document.body.appendChild(scriptForBrand)
  }
}

export const identify = ({ email, _id: id, name } = {}) => {
  const script = document.getElementById('zoho-identification') || document.createElement('script')
  const displayName = format.name(name)

  script.id = 'zoho-identification'
  script.type = 'text/javascript'
  script.text = `$zoho.salesiq.ready=function()
  {
    $zoho.salesiq.visitor.email("${email}");
    $zoho.salesiq.visitor.id("${id}");
    $zoho.salesiq.visitor.name("${displayName}")
  }`

  const existing = document.getElementsByTagName('script')[0]
  existing.parentNode.insertBefore(script, existing)
}
