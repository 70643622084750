import React from 'react'
import { TextField } from 'formik-mui'
import { Typography, Box } from '@mui/material'

const LimitedTextField = (props) => {
  const { field, schema } = props
  let { helperText = '' } = props
  const { name, value = '' } = field

  const { meta: { maxLength } = {} } = schema.fields[name] ? schema.fields[name].describe() : {}
  if (maxLength) {
    helperText = (
      <Box component="span" display="flex">
        <Box component="span" flex={1} mr={1}>
          {helperText}
        </Box>
        <Typography component="span" style={{ fontSize: '0.75rem' }}>
          {value.length} / {maxLength}
        </Typography>
      </Box>
    )
    props = { ...props, maxLength, schema: null, helperText }
  }

  // Field values must be a string.
  props = { ...props, field: { ...field, value: value || '' } }

  return (
    <TextField
      {...props}
    />
  )
}

export default LimitedTextField
