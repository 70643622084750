const yup = require('yup')
const displayLocation = require('./fields/display-location')
const { requiredFields, labelify } = require('./helpers')
const { nestedNotificationSchema } = require('./notification-schema')

const shape = labelify({
  updateType: yup.string().oneOf(['persistent', 'standard']).default('standard'),
  primary: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  details: yup.string().default(''),
  displayLocation: displayLocation(),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  expires: yup.string().oneOf(['15', '60', 'midnight', 'ondate']).default('60'),
  expiresAt: yup.date().default(() => new Date()),
})

const updateSchemaPartial = yup.object().shape(shape)
const updateSchema = updateSchemaPartial.shape(requiredFields(['updateType', 'primary', 'expires'])(shape)).shape(nestedNotificationSchema)
const updateFormSchema = updateSchema.shape({
  expiresAt: yup.mixed().datetime(),
})

module.exports = { updateSchema, updateSchemaPartial, updateFormSchema }
