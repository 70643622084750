import * as actions from './actions'

export const showFeedback = [
  //
  actions.showFeedback,
]
export const dismissCurrentMessage = [
  //
  actions.dismissCurrentMessage,
]
export const clearError = [
  //
  actions.clearError,
]
