import React from 'react'
import format from '../../../shared/format'

const Person = ({ user }) => {
  return user?.name ? (
    <span>
      { format.name(user.name) }
    </span>
  ) : null
}

export default Person
