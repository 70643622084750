import { moduleState } from 'cerebral'
import { crudActions } from '../factories'
import { upload } from '../../lib/util/cloudinary'
import apiClient from '../../lib/util/api-client'
import { captureException } from '../../lib/util/errors'
import { DesignHuddleClient } from '../../lib/util/designhuddle'

const mediaEmbedUrlService = apiClient.service('media/embedurl')

export const { find, setList, clearList, get, save, remove, updateOrder } = crudActions({ name: 'medias' })

export const validateEmbedURL = async ({ props: { query } }) => mediaEmbedUrlService.find({ query })

export const toggleActive = async ({ props: { id }, mediasService: service, get }) => {
  if (id) {
    const active = get(moduleState`entities.${id}.active`)
    const data = await service.patch(id, { active: !active })
    return { entities: { medias: [data] } }
  }
}

export const saveDesign = async ({ props, app, mediasService, store }) => {
  const getEditorToken = app.getSequence(`publications.getEditorToken`)
  const signUpload = app.getSequence('uploads.signUpload')
  const { entities = {}, values: { editableLayout: { projectId: editorProjectId } = {}, updateDownloadableDesign = false } = {} } = props
  const { medias: [{ _id, title } = {}] = [] } = entities

  if (editorProjectId && updateDownloadableDesign) {
    try {
      store.set(moduleState`design.generating`, true)

      const { access_token } = await getEditorToken()
      const designHuddleClient = new DesignHuddleClient(access_token)
      const mediaDesign = await designHuddleClient.exportProject(editorProjectId, title)

      let media = {}
      if (mediaDesign) {
        const blob = await fetch(mediaDesign.download_url).then((res) => res.blob())
        const design = await upload(blob, { signUpload, uploadPreset: 'editor-pdf', publicId: _id })
        media = await mediasService.patch(_id, { design })
      }

      store.set(moduleState`design.generating`, false)
      return { entities: { ...entities, medias: [media] } }
    } catch (error) {
      store.set(moduleState`design.generating`, false)
      captureException(error)
    }
  }
}
