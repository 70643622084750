import { moduleState, props } from 'cerebral'
import { set } from 'cerebral/factories'
import apiClient from '../../lib/util/api-client'
import * as sequences from './sequences'

export default ({ name, app }) => {
  app.on('initialized', () => {
    const setConnected = app.getSequence(`${name}.setConnected`)
    const { connected } = apiClient.io
    setConnected({ connected })
    apiClient.io.on('connect', () => setConnected({ connected: true }))
    apiClient.io.on('disconnect', () => setConnected({ connected: false }))
  })

  return {
    state: {
      connected: false,
      mobileNavOpen: false,
      updateAvailable: false,
    },
    sequences: {
      setConnected: set(moduleState`connected`, props`connected`),
      setMobileNavOpen: set(moduleState`mobileNavOpen`, props`open`),
      setUpdateAvailable: set(moduleState`updateAvailable`, true),
      ...sequences,
    },
  }
}
