import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  muted: {
    color: theme.palette.grey['700'],
  },
}))

const ActionScreen = ({ title, color, children }) => {
  const classes = useStyles()
  return (
    <Box textAlign="center" my={[20, 24, 28, 32]} mx={[8, 12, 16, 20]}>
      <Typography variant="subtitle1" classes={{ root: classes[color] }}>{title}</Typography>
      <Box mt={2}>{children}</Box>
    </Box>
  )
}

ActionScreen.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(['muted']),
  children: PropTypes.node,
}

export default ActionScreen
