import { colors, createTheme, responsiveFontSizes } from '@mui/material'
import { mergeDeepRight } from 'ramda'
import brand from '../lib/util/brand'
import { softShadows } from './shadows'
import typography from './typography'

const themeConfig = brand.theme
const { green, orange } = colors

const defaultTheme = createTheme(themeConfig)

const baseConfig = {
  direction: 'ltr',
  typography,
  shadows: softShadows,
  components: {
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'inherit',
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: defaultTheme.spacing(2),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#ddd',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
        SelectProps: {
          displayEmpty: true,
        },
        variant: 'outlined',
        margin: 'normal',
      },

      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.paper,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 8,
          minHeight: 32,
          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: 'auto',
          },
          marginRight: 24,
          opacity: 0.8,
          color: defaultTheme.palette.secondary.main,
          '&$selected': {
            color: defaultTheme.palette.secondary.main,
          },
          '&:hover': {
            opacity: 1,
            color: defaultTheme.palette.secondary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 32,
          position: 'relative',
          // Extend tabs to the side of the screen at small sizes.
          // TODO: This does not actually deserve to be global.
          [defaultTheme.breakpoints.down('md')]: {
            marginLeft: defaultTheme.spacing(-3),
            marginRight: defaultTheme.spacing(-3),
            borderBottom: `1px solid ${defaultTheme.palette.grey['200']}`,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            marginLeft: defaultTheme.spacing(-2),
            marginRight: defaultTheme.spacing(-2),
          },
          '&::after': {
            content: '" "',
            position: 'absolute',
            right: 0,
            background: 'linear-gradient(270deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)',
            height: '100%',
            width: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          ...typography.overline,
          lineHeight: 1,
          height: 'auto',
          color: defaultTheme.palette.grey['600'],
          marginTop: 16,
          marginBottom: 8,
        },
      },
    },
  },
  palette: {
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#fff',
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
      contrastText: '#fff',
    },
  },
}

export function createMUITheme(settings = {}) {
  let theme = createTheme(mergeDeepRight(baseConfig, themeConfig, { direction: settings.direction }))

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
