import React from 'react'
import { url, isImageType } from '../../lib/util/cloudinary'

const Image = ({ image = {}, alt, options = {}, className, style = {} }) => {
  if (!image) {
    return null
  }

  const { format } = options
  const isImage = isImageType(image)
  const src = url(options, image)

  if (!src) {
    return null
  }

  if (isImage || format === 'jpg') {
    return <img className={className} alt={alt} src={src} style={style} />
  } else {
    const poster = url({ ...options, format: 'jpg' }, image)
    return (
      <video controls controlsList="nodownload nofullscreen noremoteplayback" poster={poster} className={className} style={style}>
        <source src={src}/>
      </video>
    )
  }
}

export default Image
