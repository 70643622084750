import { state, props } from 'cerebral'
import { set, when } from 'cerebral/factories'
import * as actions from './actions'
import { saveEntities, redirectOnSave } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.saveDesign,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Media saved', type: 'success' }),
  route.toMedias,
]

export const saveWithoutFeedback = [
  //
  actions.save,
  actions.saveDesign,
  saveEntities(),
  when(state`route.path`, redirectOnSave),
  {
    true: [
      ({ props: { entities } }) => {
        const [result] = entities.medias
        // Redirect back to appropriate media.
        return { params: { id: result?._id } }
      },
      route.toMedia,
    ],
    false: [],
  },
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Media cleared', type: 'success' }),
  find,
]

export const removeWithoutFeedback = [
  //
  actions.remove,
]

export const toggleActive = [
  //
  actions.toggleActive,
  saveEntities(),
]

export const updateOrder = [
  //
  actions.updateOrder,
  findForList,
]

export const validateEmbedURL = [
  //
  actions.validateEmbedURL,
]
