import { Container } from '@cerebral/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import AppUI from './components/App'
import TenantLoader from './components/TenantLoader'
import { load } from './lib/util/zoho'
import cerebralApp from './modules/app'

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({ dsn: import.meta.env.VITE_SENTRY_DSN, environment: import.meta.env.VITE_ENVIRONMENT_NAME || 'production' })
}

const root = createRoot(document.getElementById('root'))
root.render(
  <Container app={cerebralApp}>
    <TenantLoader>
      <AppUI />
    </TenantLoader>
  </Container>
)

load()
