const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  active: yup.boolean().default(true),
  icon: yup.string().ensure().default('AccountBalance'),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const mapCategorieSchemaPartial = yup.object().shape(shape)
const mapCategorieSchema = mapCategorieSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { mapCategorieSchema, mapCategorieSchemaPartial }
