const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  kiosk: yup.mixed(),
  browserCommand: yup.string().oneOf(['reload', null]).nullable(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
})

const kioskDeviceSchemaPartial = yup.object().shape(shape)
const kioskDeviceSchema = kioskDeviceSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { kioskDeviceSchema, kioskDeviceSchemaPartial }
