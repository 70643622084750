const yup = require('yup')

const dayToggles = () => yup.object().shape({
  monday: yup.boolean().default(false),
  tuesday: yup.boolean().default(false),
  wednesday: yup.boolean().default(false),
  thursday: yup.boolean().default(false),
  friday: yup.boolean().default(false),
  saturday: yup.boolean().default(false),
  sunday: yup.boolean().default(false),
  holidays: yup.boolean().default(false),
})

module.exports = dayToggles
