const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().ensure(),
  imei: yup.string().ensure(),
  sim: yup.string().ensure(),
  active: yup.boolean().default(true),
  locationVehicle: yup.mixed().nullable(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationDeviceSchemaPartial = yup.object().shape(shape)
const locationDeviceSchema = locationDeviceSchemaPartial.shape(requiredFields(['name', 'imei'])(shape))

module.exports = { locationDeviceSchema, locationDeviceSchemaPartial }
