const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  eventType: yup.string().default(''),
  locationVehicleShift: yup.mixed(),
  locationVehicle: yup.mixed(),
  latitude: yup.number(),
  longitude: yup.number(),
  reportedAt: yup.date(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationDeviceEventSchemaPartial = yup.object().shape(shape)
const locationDeviceEventSchema = locationDeviceEventSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationDeviceEventSchema, locationDeviceEventSchemaPartial }
