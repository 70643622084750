import * as Sentry from '@sentry/browser'

const authRedundantErrors = ['jwt expired', 'No accessToken found in storage']

export const captureException = (error, extra = {}) => {
  if (authRedundantErrors.includes(error.message)) {
    return
  }
  console.error(error, extra)
  Sentry.captureException(error, { extra })
}
