import { connect } from '@cerebral/react'
import { Box, Grid, InputAdornment, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Delete } from '@mui/icons-material'
import { Alert, AlertTitle } from '@mui/material'
import { sequences, state } from 'cerebral'
import { FastField, FieldArray } from 'formik'
import { groupBy, map, pick, pluck, prop, sum } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import schemaSettings from '../../../../../shared/schemas/resort-schema'
import ValidatedForm from '../../../blocks/ValidatedForm'
import BetweenTimes from '../../../controls/BetweenTimes'
import ConfirmDialog from '../../../controls/ConfirmDialog'
import DebouncedColorPicker from '../../../controls/DebouncedColorPicker'
import LimitedTextField from '../../../controls/LimitedTextField'
import ThemedIconButton from '../../../controls/ThemedIconButton'
import { AddButton } from '../../../elements/AddButton'

const { eventLabelsSchema, settingsFormSchema } = schemaSettings

const useStyles = makeStyles((theme) => ({
  inlineFields: {
    [theme.breakpoints.up('md')]: { flex: '1' },
  },
}))

const EventSettings = connect(
  {
    organization: state`account.organization`,
    saveSettings: sequences`account.saveSettings`,
    packages: state`account.packages`,
  },
  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['eventSettings'], values) }),
  }),
  ({ organization, saveSettings, packages }) => {
    const classes = useStyles()
    const eventsEnabled = packages['events']
    const mealsEnabled = packages['menu']
    const [deletingEventType, setDeletingEventType] = useState(null)
    const closeDeletingEventType = useCallback(() => setDeletingEventType(null), [setDeletingEventType])
    const [deletingMealType, setDeletingMealType] = useState(null)
    const closeDeletingMealType = useCallback(() => setDeletingMealType(null), [setDeletingMealType])

    return organization?._id ? (
      <ValidatedForm
        title="Event Settings"
        section="Organization"
        initialValues={organization}
        schema={settingsFormSchema}
        onSubmit={saveSettings}
        validateOnChange={false}
      >
        {({ isSubmitting, values: { eventSettings: { eventLabels = [] } = {} } }) => {
          const percents = pluck('monthTargetPercent', eventLabels).join(',')

          const monthlyDistribution = useMemo(() => {
            const grouped = groupBy(prop('category'), eventLabels)
            const summary = map((labels) => ({ count: labels.length, percent: sum(pluck('monthTargetPercent', labels)) }), grouped)
            return { Event: { percent: 0, count: 0 }, Menu: { percent: 0, count: 0 }, ...summary }
          }, [percents])

          return (
            <Box flex={1}>
              {eventsEnabled ? (
                <>
                  <Typography variant="h3" gutterBottom>
                    Event Types
                  </Typography>

                  {monthlyDistribution.Event?.count > 0 && monthlyDistribution.Event?.percent !== 100 && (
                    <Box my={2}>
                      <Alert severity="error">
                        <AlertTitle>Invalid distribution.</AlertTitle>
                        Please ensure that the combined percentage for all event types adds up to <strong>100%</strong>. Currently, the total is{' '}
                        {monthlyDistribution.Event.percent}%.
                      </Alert>
                    </Box>
                  )}

                  <FieldArray
                    name="eventSettings.eventLabels"
                    render={(arrayHelpers) => {
                      const eventTypeLabel = eventLabels[deletingEventType]?.label
                      return (
                        <>
                          <ConfirmDialog
                            open={deletingEventType !== null}
                            title="Delete event type"
                            onConfirm={() => arrayHelpers.remove(deletingEventType)}
                            onClose={closeDeletingEventType}
                            confirmText="Delete"
                          >
                            Are you sure you want to delete {eventTypeLabel ? <strong>{eventTypeLabel}</strong> : 'this event type'}? If you delete this event
                            type, any past events that it is associated with it will no longer have a type.
                          </ConfirmDialog>

                          {eventLabels.map((label, index) => {
                            if (label.category !== 'Event') return null

                            return (
                              <Box key={label._id || `${label.name}:${index}`}>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={11} sm={11} classes={{ item: classes.inlineFields }}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={3}>
                                        <FastField
                                          label="Label"
                                          name={`eventSettings.eventLabels.${index}.label`}
                                          component={LimitedTextField}
                                          schema={settingsFormSchema}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <FastField label="Color" name={`eventSettings.eventLabels.${index}.color`} component={DebouncedColorPicker} />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <Tooltip title="Minimum number of events of this type per week">
                                          <div>
                                            <FastField
                                              label="# of events"
                                              name={`eventSettings.eventLabels.${index}.minEventsPerWeek`}
                                              component={LimitedTextField}
                                              schema={settingsFormSchema}
                                              type="number"
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <Tooltip title="Monthly target percent (%) of activities">
                                          <div>
                                            <FastField
                                              schema={settingsFormSchema}
                                              label="% of activities"
                                              name={`eventSettings.eventLabels.${index}.monthTargetPercent`}
                                              component={LimitedTextField}
                                              type="number"
                                              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1} sm={1}>
                                    <Box p={1}>
                                      <ThemedIconButton color="danger" onClick={() => setDeletingEventType(index)}>
                                        <Delete />
                                      </ThemedIconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          })}

                          <Box mt={1} mb={5} display="flex" justifyContent="flex-start">
                            <AddButton disabled={isSubmitting} onClick={() => arrayHelpers.push(eventLabelsSchema.cast())} title="Add an event type" />
                          </Box>
                        </>
                      )
                    }}
                  />
                </>
              ) : null}

              {mealsEnabled ? (
                <Box mt={4}>
                  <Typography variant="h3" gutterBottom>
                    Meal Types
                  </Typography>

                  {monthlyDistribution.Menu?.count > 0 && monthlyDistribution.Menu?.percent !== 100 && (
                    <Box my={2}>
                      <Alert severity="error">
                        <AlertTitle>Invalid distribution.</AlertTitle>
                        Please ensure that the combined percentage for all event types adds up to <strong>100%</strong>. Currently, the total is{' '}
                        {monthlyDistribution.Menu.percent}%.
                      </Alert>
                    </Box>
                  )}

                  <FieldArray
                    name="eventSettings.eventLabels"
                    render={(arrayHelpers) => {
                      const mealTypeLabel = eventLabels[deletingMealType]?.label
                      return (
                        <>
                          <ConfirmDialog
                            open={deletingMealType !== null}
                            title={`Delete meal type`}
                            onConfirm={() => arrayHelpers.remove(deletingMealType)}
                            onClose={closeDeletingMealType}
                            confirmText="Delete"
                          >
                            Are you sure you want to delete {mealTypeLabel ? <strong>{mealTypeLabel}</strong> : 'this meal type'}? If you delete this meal type,
                            any past meals that it is associated with it will no longer have a type.
                          </ConfirmDialog>

                          {eventLabels.map((label, index) => {
                            if (label.category !== 'Menu') return null
                            return (
                              <Box key={index}>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={11} sm={11} classes={{ item: classes.inlineFields }}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={2}>
                                        <FastField
                                          label="Label"
                                          name={`eventSettings.eventLabels.${index}.label`}
                                          component={LimitedTextField}
                                          schema={settingsFormSchema}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <FastField label="Color" name={`eventSettings.eventLabels.${index}.color`} component={DebouncedColorPicker} />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <BetweenTimes
                                          required
                                          startLabel="Start Time"
                                          startName={`eventSettings.eventLabels.${index}.start`}
                                          endLabel="End Time"
                                          endName={`eventSettings.eventLabels.${index}.end`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <Tooltip title="Minimum number of events of this type per week">
                                          <div>
                                            <FastField
                                              label="# of events"
                                              name={`eventSettings.eventLabels.${index}.minEventsPerWeek`}
                                              component={LimitedTextField}
                                              schema={settingsFormSchema}
                                              type="number"
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <Tooltip title="Monthly target percent (%) of activities">
                                          <div>
                                            <FastField
                                              schema={settingsFormSchema}
                                              label="% of activities"
                                              name={`eventSettings.eventLabels.${index}.monthTargetPercent`}
                                              component={LimitedTextField}
                                              type="number"
                                              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1} sm={1}>
                                    <Box p={1}>
                                      <ThemedIconButton color="danger" onClick={() => setDeletingMealType(index)}>
                                        <Delete />
                                      </ThemedIconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          })}

                          <Box mt={1} display="flex" justifyContent="flex-start">
                            <AddButton
                              disabled={isSubmitting}
                              onClick={() => arrayHelpers.push({ ...eventLabelsSchema.cast(), category: 'Menu' })}
                              title="Add a meal type"
                            />
                          </Box>
                        </>
                      )
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          )
        }}
      </ValidatedForm>
    ) : null
  }
)

export default EventSettings
