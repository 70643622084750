import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import AccountIcon from './icon/Reg/Account.svg?react'
import ArchiveIcon from './icon/Reg/Archive.svg?react'
import AttendanceIcon from './icon/Reg/Attendance.svg?react'
import CalendarIcon from './icon/Reg/Calendar.svg?react'
import CapacityIcon from './icon/Reg/Capacity.svg?react'
import CautionIcon from './icon/Reg/Caution.svg?react'
import DraftsIcon from './icon/Reg/Draft.svg?react'
import EventsIcon from './icon/Reg/Events.svg?react'
import HailIcon from './icon/Reg/Hail.svg?react'
import FeaturedIcon from './icon/Reg/Featured.svg?react'
import GondolaIcon from './icon/Reg/Gondola.svg?react'
import ImagePlaceholder from './icon/Reg/ImagePlaceholder.svg?react'
import LiveIcon from './icon/Reg/Live.svg?react'
import MealsIconSvg from './icon/Reg/Menu.svg?react'
import NotificationIcon from './icon/Reg/Notification.svg?react'
import OpenInNew from './icon/Reg/OpenInNew.svg?react'
import PastIcon from './icon/Reg/Past.svg?react'
import PricingIcon from './icon/Reg/Pricing.svg?react'
import ReservationsIcon from './icon/Reg/Reservations.svg?react'
import ResortDetailsIcon from './icon/Reg/ResortDetails.svg?react'
import SurveyIcon from './icon/Reg/Survey.svg?react'
import TicketTypeIcon from './icon/Reg/Ticket_Type.svg?react'
import TrailsIcon from './icon/Reg/Trails.svg?react'
import UpcomingIcon from './icon/Reg/Upcoming.svg?react'
import UsersIconSvg from './icon/Reg/Users.svg?react'
import VideoIcon from './icon/Reg/Video.svg?react'
import WeatherIcon from './icon/Reg/Weather.svg?react'
import RepeatIcon from './icon/Small/repeat.svg?react'
import WavingHand from './icon/Reg/WavingHand.svg?react'

import cc from 'classcat'

const useStyles = makeStyles(() => ({
  icon: {
    '& path, & g': {
      fill: 'currentColor',
      fillOpacity: 1,
    },
  },
}))

const wrapIcon = (Component) => (props) => {
  const classes = useStyles()
  return <Component {...props} className={cc([classes.icon, props.className])} />
}

// TODO: Either wrap more icons with color style, or determine how to inject this in Webpack.
const MealsIcon = wrapIcon(MealsIconSvg)
const UsersIcon = wrapIcon(UsersIconSvg)

export {
  AccountIcon,
  ArchiveIcon,
  AttendanceIcon,
  CalendarIcon,
  CapacityIcon,
  CautionIcon,
  DraftsIcon,
  EventsIcon,
  FeaturedIcon,
  GondolaIcon,
  HailIcon,
  ImagePlaceholder,
  LiveIcon,
  MealsIcon,
  NotificationIcon,
  OpenInNew,
  PastIcon,
  PricingIcon,
  RepeatIcon,
  WavingHand,
  ReservationsIcon,
  ResortDetailsIcon,
  SurveyIcon,
  TicketTypeIcon,
  TrailsIcon,
  UpcomingIcon,
  UsersIcon,
  VideoIcon,
  WeatherIcon,
}
