const yup = require('yup')
const { requiredFields } = require('./helpers')

const shape = {
  asset_id: yup.string(),
  public_id: yup.string(),
  version: yup.number(),
  version_id: yup.string(),
  signature: yup.string(),
  description: yup.string(),
  width: yup.number(),
  height: yup.number(),
  format: yup.string(),
  resource_type: yup.string(),
  created_at: yup.string(),
  tags: yup.array(),
  bytes: yup.number(),
  type: yup.string(),
  etag: yup.string(),
  placeholder: yup.boolean(),
  url: yup.string(),
  secure_url: yup.string(),
  access_mode: yup.string(),
  original_filename: yup.string(),
  original_extension: yup.string(),
}

const uploadSchema = yup.object().shape(shape).nullable()
const uploadSchemaRequired = yup
  .object()
  .shape(shape)
  .shape(requiredFields(['url', 'secure_url'])(shape))

module.exports = { uploadSchema, uploadSchemaRequired }
