import * as sequences from './sequences'
import { currentMessage } from './computed'

export default () => ({
  state: {
    messages: [],
    currentMessage,
  },
  sequences,
})
