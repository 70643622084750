import { connect } from '@cerebral/react'
import { MenuItem, TextField, Typography } from '@mui/material'
import { sequences, state } from 'cerebral'
import omit from 'ramda/src/omit'
import React from 'react'

const omitParams = omit(['$sort'])

const FilterBy = connect(
  {
    route: state`route`,
    navigate: sequences`route.${state`route.key`}`,
  },

  ({ param = 'filter', label, options = [], showAll = true, route, navigate }) => {
    const { params, query, path } = route
    const merged = { ...params, ...query }
    const filter = merged[param] || 'all'

    return (
      <TextField
        select
        margin="none"
        label={label}
        value={filter === null ? 'all' : filter}
        onChange={({ target: { value } }) => {
          value = value === 'all' ? undefined : value
          navigate({ params: { ...omitParams(merged), [param]: value }, path })
        }}
      >
        {showAll && (
          <MenuItem value="all">
            <Typography variant="inherit" color="textSecondary">
              Show all
            </Typography>
          </MenuItem>
        )}
        {options.map(({ label, value }) => {
          return (
            <MenuItem key={value} value={value}>
              Filter by: {label}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }
)

export default FilterBy
