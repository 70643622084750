import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { paramsForServer } from 'feathers-hooks-common'
import { mergeDeepLeft } from 'ramda'

// Including the transports option forces the browser to go straight to web sockets.
const socket = io({ path: '/api/socket.io', transports: ['websocket'] })
const apiClient = feathers()
apiClient.configure(socketio(socket, { timeout: 10 * 1000 }))
apiClient.configure(auth({
  storageKey: 'app.auth'
}))

const orgParams = {}

export const subscribeOrganization = ({ app: cerebralApp }) => {
  cerebralApp.once('initialized:model', () => {
    const { orgSlug, locationSlug } = cerebralApp.getState('account')
    orgParams.orgSlug = orgSlug
    orgParams.locationSlug = locationSlug
  })

  cerebralApp.on('flush', (changes) => {
    changes.forEach(({ path }) => {
      if (path[0] === 'account' && path[1] === 'orgSlug') {
        orgParams.orgSlug = cerebralApp.getState(path)
      }
    })
  })

  return {}
}

const addOrganizationParams = (feathersApp) => {
  feathersApp.hooks({
    before: {
      all: (context) => {
        const params = paramsForServer(orgParams)
        return mergeDeepLeft({ params }, context)
      },
    }
  })
}

apiClient.configure(addOrganizationParams)

export default apiClient
