const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')

const shape = labelify({
  resort: yup.string(),
  eventInstance: yup.string(),
  mobileUser: yup.mixed(),
  profile: yup.mixed(),
  participated: yup.boolean().default(true),
  participationStatus: yup.string().oneOf(['didnotshow', 'refused', 'active', 'passive']).default('active'),
  notes: yup.string().ensure(),
  image: uploadSchema,
})

const eventAttendanceSchemaPartial = yup.object().shape(shape)
const eventAttendanceSchema = eventAttendanceSchemaPartial.shape(requiredFields(['profile'])(shape))

const eventAttendanceSchemaResponseSchema = eventAttendanceSchema.shape({
  createdAt: yup.date().nullable(),
  createdBy: yup.mixed(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.mixed(),
})

const eventAttendanceBulkSchema = yup.object().shape({
  attendances: yup.array().of(eventAttendanceSchema),
})

module.exports = { eventAttendanceSchema, eventAttendanceSchemaPartial, eventAttendanceSchemaResponseSchema, eventAttendanceBulkSchema }
