import { Grid } from '@mui/material'
import { Field } from 'formik'
import React from 'react'
import PropTypes from 'prop-types'
import HourMinutesText from './HoursMinutesText'

const BetweenTimes = ({ startName, startLabel = 'Start', endName, endLabel = 'End', size }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field label={startLabel} name={startName} component={HourMinutesText} size={size} />
      </Grid>
      <Grid item xs={6}>
        <Field label={endLabel} name={endName} component={HourMinutesText} size={size} />
      </Grid>
    </Grid>
  )
}

BetweenTimes.propTypes = {
  startName: PropTypes.string.isRequired,
  startLabel: PropTypes.string,
  endName: PropTypes.string.isRequired,
  endLabel: PropTypes.string,
  size: PropTypes.string,
}

export default BetweenTimes
