const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const appointmentTypes = require('../appointment-types')

const shape = labelify({
  title: yup.string().default(''),
  profile: yup.mixed().required(),
  provider: yup.string().default('').nullable(),
  location: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  locationLinkOnMobile: yup.boolean().default(false),
  needsTransportation: yup.boolean().default(false),
  details: yup.string().nullable(),
  startAt: yup.date().default(() => new Date()),
  endAt: yup
    .date()
    .min(yup.ref('startAt'))
    .default(() => new Date()),
  type: yup.string().ensure().oneOf(appointmentTypes).default('Healthcare'),
  published: yup.boolean().default(false),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const appointmentSchemaPartial = yup.object().shape(shape)

const appointmentSchema = appointmentSchemaPartial.shape(requiredFields(['title', 'profile'])(shape))

const appointmentFormSchema = appointmentSchema.shape({
  startAt: yup.mixed().datetime(),
  endAt: yup.mixed().datetime(),
})

module.exports = { appointmentSchema, appointmentSchemaPartial, appointmentFormSchema }
