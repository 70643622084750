const yup = require('yup')
const { labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().ensure(),
  slug: yup.string().ensure(),
  path: yup.array().of(yup.string()).default([]).transform(v => v || []),
  value: yup.string().nullable(),
  override: yup.string().nullable(),
})

const resortStatSchema = yup.object().shape(shape)

module.exports = { resortStatSchema }
