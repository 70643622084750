export const pdfMakeLayouts = {
  minimalSpaceLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingLeft: () => 1.5,
    paddingRight: () => 1.5,
    paddingTop: () => 1.5,
    paddingBottom: () => 1.5,
  },
  noLines: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
  },
  thinLines: {
    hLineWidth: () => 0.15,
    vLineWidth: () => 0.15,
  },
  dayGridLayout: {
    hLineWidth: () => 0.15,
    vLineWidth: () => 0.15,
    paddingLeft: () => 0,
    paddingRight: () => 0,
    paddingTop: () => 0,
    paddingBottom: () => 0,
  },
}

// 1 inch equals 72 units in pdf-lib dimensions
export const convertInchesToPDFLibUnit = (inches) => inches * 72

export default { pdfMakeLayouts }
