const yup = require('yup')

const shape = {
  mobileUser: yup.mixed(),
  value: yup.number(),
  updatedAt: yup.date(),
}

const surveyResponseSchema = yup.object().shape(shape)

module.exports = { surveyResponseSchema }
