export const getPdfArrayBuffer = async (pdfDocumentDefinition) => {
  // Async import for code-splitting.
  const { default: pdfMake } = await import('./pdfmake')

  return new Promise((resolve, reject) => {
    const pdf = pdfMake.createPdf(pdfDocumentDefinition)
    pdf.getBuffer(
      (blob) => resolve(blob),
      (error) => reject(error)
    )
  })
}
