import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Notification saved', type: 'success' }),
  route.toUpdates,
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Notification cleared', type: 'success' }),
  find,
]

export const getDisplaysCount = [
  //
  actions.getDisplaysCount,
]

export const setFormDefaults = [
  //
  actions.setFormDefaults,
]

export const setUpdateForBirthday = [
  //
  actions.initBirthdayDefaults,
  actions.setFormDefaults,
  set(props`params.id`, 'create'),
  route.toUpdate,
]
