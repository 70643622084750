const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  description: yup.string().ensure(),
  observedAt: yup.date(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  reviewed: yup.bool(),
  deleted: yup.bool(),
})

const appreciationAwardSchemaPartial = yup.object().shape(shape)
const appreciationAwardSchema = appreciationAwardSchemaPartial.shape(requiredFields(['description'])(shape))
const appreciationAwardResponseSchema = appreciationAwardSchemaPartial.shape({
  authorUser: yup.mixed(),
  recipientProfile: yup.mixed(),
})

const appreciationAwardFormSchema = appreciationAwardSchema.shape({
  observedAt: yup.mixed().datetime(),
})

module.exports = { appreciationAwardSchema, appreciationAwardSchemaPartial, appreciationAwardResponseSchema, appreciationAwardFormSchema }
