const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.mixed(),
  title: yup.string().default(''),
  phoneNumberLabel: yup.string().default('Phone'),
  phoneNumber: yup.string().ensure(),
  secondaryPhoneNumberLabel: yup.string().default('Phone'),
  secondaryPhoneNumber: yup.string().ensure(),
  emailAddressLabel: yup.string().default('Email'),
  email: yup.string().email(),
  website: yup.string().default(''),
  isEmergencyContact: yup.boolean().default(false),
  isPrimaryContact: yup.boolean().default(false),
  sortOrder: yup.number().min(0),
})

const contactInfoSchemaPartial = yup.object().shape(shape)
const contactInfoSchema = contactInfoSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { contactInfoSchema, contactInfoSchemaPartial }
