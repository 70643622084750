const yup = require('yup')
const { labelify } = require('./helpers')

const shape = labelify({
  profiles: yup.array().of(yup.mixed()).required(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
})

const profileRelationshipSchema = yup.object().shape(shape)

module.exports = { profileRelationshipSchema }
