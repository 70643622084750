import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import cc from 'classcat'
import PropTypes from 'prop-types'
import React from 'react'
import Color from 'color'

const icons = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles((theme) => {
  const lgPadding = theme.spacing(3)
  return {
    Paper: {
      backgroundColor: theme.palette.grey[100],
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    'size-large': {
      padding: lgPadding,
    },
    'size-medium': {
      padding: theme.spacing(2),
    },
    'size-small': {
      padding: theme.spacing(1),
    },
    margin: {
      marginBottom: lgPadding,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '10px',
    },
    error: {
      backgroundColor: Color(theme.palette.error.light).lighten(0.45).toString(),
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.dark,
    },
  }
})

const Notice = ({ variant, margin = true, size = 'large', children }) => {
  const classes = useStyles()
  const NoticeIcon = icons[variant]
  const message = NoticeIcon ? (
    <div className={classes.message}>
      <NoticeIcon className={classes.icon} /> {children}
    </div>
  ) : (
    children
  )
  return (
    <Paper classes={{ root: cc([classes.Paper, classes[`size-${size}`], margin && classes.margin, classes[variant]]) }} elevation={0}>
      {message}
    </Paper>
  )
}

Notice.propTypes = {
  variant: PropTypes.oneOf(['info', 'error', 'success']),
  margin: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
}

export default Notice
