import React from 'react'
import { connect } from '@cerebral/react'
import { sequences } from 'cerebral'
import { TextField, InputAdornment, Icon } from '@mui/material'

const Search = connect(
  {
    setSearch: sequences`route.setSearch`,
  },

  ({ setSearch, placeholder = 'Search' }) => {
    return (
      <TextField
        variant="outlined"
        margin="none"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          ),
        }}
        onChange={({ target: { value } }) => {
          setSearch({ search: [value] })
        }}
      />
    )
  }
)

export default Search
