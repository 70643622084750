import errors from '@feathersjs/errors'
import { moduleState, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { ascend, compose, filter, findIndex, flatten, identity, indexBy, mapObjIndexed, pluck, prop, sort, uniq, values } from 'ramda'
import { move } from 'ramda-adjunct'
import { expandEntities, expandEntity } from './computed'
import { showFeedbackFromError } from './feedback/factories'
import { sequences as route } from './route'

export const saveEntities = () => ({ props: { entities = {} }, store }) => {
  mapObjIndexed((collection, type) => {
    const indexed = indexBy(prop('_id'), collection)
    store.merge(state`${type}.entities`, indexed)
  }, entities)
}

const anonymousRoute = (key) => ['toLogin'].includes(key)

export const redirectOnSave = (path) => /create|duplicate/i.test(path)

export const whenRouteSecured = (sequence) => [
  when(state`route.key`, anonymousRoute),
  {
    true: [],
    false: sequence,
  },
]

export const redirectUnauthorized = () => [
  errors.NotAuthenticated,
  [
    set(state`account.checked`, true),
    showFeedbackFromError({ title: 'Please Log In', ignore: /^No accessToken found|Reauthentication error/ }),
    whenRouteSecured([set(state`account.redirectedFromPath`, state`route.path`), route.toLogin]),
  ],
]

export const createCrudState = ({ entityType, storePrefix }) => ({
  viewing: expandEntity(entityType, state`route.params.id`),
  loading: false,
  list: {
    ids: [],
    data: expandEntities(entityType, state`${storePrefix}.list.ids`),
    limit: 0,
    skip: 0,
    total: 0,
  },
  entities: {},
  defaultFormValues: {},
})

export const crudActions = ({ name, schemas = {} }) => {
  const serviceName = `${name}Service`
  const validationName = `${name}Validation`

  const find = async ({
    props: { query = {}, useRouteParams, entities: passedEntities = {}, fetchAll = false, silent = false },
    [serviceName]: service,
    [validationName]: validation,
    get,
    store,
  }) => {
    validation = schemas.find || validation
    if (useRouteParams) {
      const params = get(state`route.params`) || {}
      const querystring = get(state`route.query`) || {}
      query = { ...query, ...params, ...querystring }
    }
    if (!silent) {
      store.set(moduleState`list.loading`, true)
    }
    let err = null
    let { data, ...result } = await service.find(query).catch((error) => (err = error))

    // If query has a $limit, check number of items reached limit before making other requests.
    const isLimitReached = query['$limit'] ? query['$limit'] === data.length : true

    // NOTE: There is no upper bound on the number of records fetchAll will fetch. It should only be used with a reasonably constrained query.
    if (fetchAll && data.length && data.length < result.total && isLimitReached) {
      const remainingPages = Math.ceil((result.total - data.length) / data.length)
      for (let index = 0; index < remainingPages; index++) {
        query.$skip = data.length
        let { data: pageData = [] } = await service.find(query).catch((error) => (err = error))
        data = data.concat(pageData)
      }
    }
    if (!silent) {
      store.set(moduleState`list.loading`, false)
    }
    if (err) {
      throw err
    }
    const ids = pluck('_id', data)
    const entities = validation ? data.map((entity) => validation.cast(entity)) : data
    return { result: { ids, ...result }, entities: { ...passedEntities, [name]: entities } }
  }

  const setList = ({ props, store }) => {
    store.merge(moduleState`list`, props.result)
  }

  const clearList = ({ store }) => {
    store.merge(moduleState`list`, {
      ids: [],
      limit: 0,
      skip: 0,
      total: 0,
    })
  }

  const setFormDefaults = ({ props: { values }, store }) => {
    store.set(moduleState`defaultFormValues`, values)
  }

  const get = async ({ props: { id, entities = {} }, [serviceName]: service, [validationName]: validation }) => {
    validation = schemas.find || validation
    let item = await service.get(id)
    if (validation) {
      item = validation.cast(item, { stripUnknown: true })
    }
    return { entities: { ...entities, [name]: [item] } }
  }

  const save = async ({ props: { values, entities = {} }, [serviceName]: service, [validationName]: validation }) => {
    validation = schemas.save || validation
    const { _id } = values
    const data = await validation.validate(values)
    const item = await (_id ? service.patch(_id, data) : service.create(data))
    let items = Array.isArray(item) ? item : [item]
    // Returned value should be cast by find schema if available.
    if (schemas.find || validation) {
      items = items.map((item) => (schemas.find || validation).cast(item))
    }
    return { entities: { ...entities, [name]: items } }
  }

  const remove = async ({ props: { id, deleteAssociatedItems }, [serviceName]: service, store, get }) => {
    const query = deleteAssociatedItems ? { deleteAssociatedItems } : null

    const values = await service.remove(id, { query })
    // Remove id from list, if present.
    const ids = get(moduleState`list.ids`)
    const index = findIndex((listid) => listid === id, ids)
    if (index > -1) {
      store.splice(moduleState`list.ids`, index, 1)
    }
    // Remove entity from entities store.
    store.unset(moduleState`entities.${id}`)
    return { values }
  }

  const updateOrder = async ({ props, get, store, [serviceName]: service }) => {
    const { id, from, to } = props
    let list = get(moduleState`list.data`)
    const fromItem = list[from]
    const toItem = list[to]
    list = move(from, to, list)
    const ids = pluck('_id', list)
    // Optimistic update of list.
    store.merge(moduleState`list`, { ids, list })
    // Update sort on server. Use sortOrder with a fallback to index if not present.
    await service.patch(id, { sortOrder: to, sort: { from: fromItem?.sortOrder ?? from, to: toItem?.sortOrder ?? to } })
  }

  return { find, setList, clearList, get, save, remove, updateOrder, setFormDefaults }
}

export const tagActions = ({ fieldName = 'tags' } = {}) => {
  const uniqTags = compose(sort(ascend(identity)), uniq, flatten, filter(identity), pluck(fieldName), values)

  const collectTags = ({ get, store }) => {
    const entities = get(moduleState`entities`)
    const tags = uniqTags(entities)
    store.set(moduleState`allTags`, tags || [])
  }

  return { collectTags }
}
