import { state } from 'cerebral'
import { redirectUnauthorized } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'
import * as sequences from './sequences'

export default ({ name }) => ({
  state: {},
  sequences,
  catch: [
    redirectUnauthorized(),
    [
      Error,
      showFeedbackFromError({ title: 'We encountered an error' }),
      ({ props: { error, errorPath = name }, store }) => {
        store.set(state`errors.${errorPath}`, error)
      },
    ],
  ],
})
