import { props, state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { sequences as route } from '../route'
import * as actions from './actions'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  actions.clearList,
  saveEntities(),
  when(props`values.send`),
  {
    true: [
      //
      showFeedback({ title: 'Invitation sent', type: 'success' }),
      route.toInvitations,
    ],
    false: [
      //
      showFeedback({ title: 'Person saved', type: 'success' }),
      when(state`route.query.redirectTo`),
      {
        true: [
          //
          set(props`path`, state`route.query.redirectTo`),
          set(props`query.profile`, props`entities.profiles.0._id`),
          route.toPath,
        ],
        false: [
          //
          route.toPeople,
        ],
      },
    ],
  },
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Invitation deleted', type: 'success' }),
  findForList,
]

export const changeRole = [
  //
  actions.changeRole,
  showFeedback({ title: `Changed user role`, type: 'success' }),
  findForList,
]

export const send = [
  //
  actions.send,
  showFeedback({ title: 'Invitation sent', type: 'success' }),
]

export const update = [
  //
  actions.update,
  showFeedback({ title: 'Invitation sent', type: 'success' }),
  route.toPeople,
]

export const setBulkQueue = [
  //
  actions.setBulkQueue,
]

export const saveBulk = [
  //
  actions.save,
  saveEntities(),
]

export const processBulkQueue = [
  //
  actions.processBulkQueue,
]
