const yup = require('yup')
const mobileUserTypes = require('../mobile-user-types')

const shape = {
  name: yup.string().ensure(),
  prompt: yup.string().ensure(),
  frequency: yup.string().oneOf(['day', 'week', 'month']).required().default('day'),
  range: yup.number().min(1).default(2).required(),
  labels: yup.array().of(yup.string()).default([]),
  selectionType: yup.string().oneOf(['thumbs', 'stars', 'faces', 'nps']).default('faces'),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  disabled: yup.boolean().default(false),
  responseCount: yup.number(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
}

const surveySchema = yup.object().shape(shape)

module.exports = { surveySchema }
