import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GetApp } from '@mui/icons-material'
import PropTypes from 'prop-types'
import QRCode from 'qrcode'
import React, { useEffect, useState } from 'react'
import ThemedButton from '../controls/ThemedButton'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    textAlign: 'center',
  },
  image: {
    width: '100%',
  },
}))

const QrCode = ({ code, width, download }) => {
  const classes = useStyles()
  const [uri, setUri] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    QRCode.toDataURL(code, { width, margin: 2 }, (err, url) => {
      setError(err)
      setUri(url)
    })
  }, [code, width])

  return (
    <Box className={classes.container}>
      {uri && <img src={uri} title={code} className={classes.image} />}
      {Boolean(uri && download) && <ThemedButton startIcon={<GetApp />} variant="outlined" component="a" href={uri} download={download}>Download QR Code</ThemedButton>}
      {error && <Typography>Error: {error.message}</Typography>}
    </Box>
  )
}

QrCode.propTypes = {
  code: PropTypes.string.isRequired,
  download: PropTypes.string,
  width: PropTypes.number,
}

export default QrCode
