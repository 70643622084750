import { identity, ifElse, is, omit } from 'ramda'
import { useEffect } from 'react'

export const omitConnectProps = omit(['get', 'reaction'])
export const omitStateFlag = ifElse(is(Object), omit(['__CerebralState']), identity)

// Cerebral's reaction, wrapped to invoke on creation and to prevent running during unmount.
export const reactionLifecycle = (create, name, deps, cb, { initial = true } = {}) => {
  useEffect(() => {
    let mounted = true
    if (initial) {
      cb()
    }

    create(name, deps, (data) => {
      // We check for mounted, because reaction.destroy isn't fast enough to prevent a final watch call.
      if (mounted) {
        cb(data)
      }
    })

    return () => {
      mounted = false
    }
  }, [])
}

export const mergePropsFactory = (cb) => {
  // A function, because it is bound on a View, which allows us to access the createReaction method.
  // This is something that would happen automatically if we did not provide this mergeProps argument.
  return function (props, ownProps, get) {
    const reaction = this.createReaction
    const merged = cb(props, ownProps)
    return { ...merged, get, reaction }
  }
}
