import { props } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { compose, path, pluck } from 'ramda'
import { saveEntities } from '../factories'
import { loadProfilesSequence } from '../profiles/factories'
import * as actions from './actions'

const loadProfiles = loadProfilesSequence(compose(pluck('mobileUser'), path(['props', 'entities', 'surveyResponses'])))

export const find = [
  actions.find,
  when(props`useRouteParams`),
  {
    true: actions.setList,
    false: [],
  },
  saveEntities(),
  loadProfiles,
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  find,
]

export const get = [
  //
  actions.get,
  saveEntities(),
  loadProfiles,
]
