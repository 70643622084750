import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'locationVehicleShifts' })

export const endShift = async ({ props: { id }, locationVehicleShiftsService: service, get }) => {
  if (id) {
    const data = await service.patch(id, { endedAt: new Date() })
    return { entities: { events: [data] } }
  }
}
