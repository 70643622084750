const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  profile: yup.mixed().required(),
  product: yup.mixed().required(),
  rideProductType: yup.string().oneOf(['anytime', 'daily']).default('anytime'),
  accessCodes: yup.mixed(),
  startAt: yup
    .date()
    .required()
    .default(() => new Date()),
  expireAt: yup
    .date()
    .min(yup.ref('startAt'))
    .default(() => new Date()),
  ridesIncluded: yup.number().required().min(1).default(1),
  ridesUsed: yup.number().required().min(0).default(0),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const rideProductSubscriptionSchemaPartial = yup.object().shape(shape)
const rideProductSubscriptionSchema = rideProductSubscriptionSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { rideProductSubscriptionSchema, rideProductSubscriptionSchemaPartial }
