import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

function Address({ address = {}, variant = 'body2', showEmptyState = false }) {
  const isAddressEmpty = useMemo(() => Object.values(address).filter(Boolean).length === 0, [address])

  return !isAddressEmpty ? (
    <>
      <Typography variant={variant}>
        {address.street1} {address.street2}
      </Typography>
      {address.city && address.state && (
        <Box my={1}>
          <Typography variant={variant}>
            {address.city}, {address.state} {address.zip}
          </Typography>
        </Box>
      )}
    </>
  ) : showEmptyState ? (
    <Typography variant={variant}>N/A</Typography>
  ) : null
}

Address.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  showEmptyState: PropTypes.bool,
  variant: PropTypes.string,
}

export default Address
