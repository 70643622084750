const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  provider: yup.mixed(),
  providerId: yup.string(),
  title: yup.string().ensure(),
  subtitle: yup.string().ensure(),
  description: yup.string().ensure(),
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        displayName: yup.string().ensure(),
        url: yup.string().ensure(),
        sizeBytes: yup.number().default(0),
        durationSeconds: yup.number().default(0),
        suitableForDisplay: yup.boolean().default(true),
        fileType: yup.string().ensure(),
      })
    )
    .default([]),
  thumbnailURL: yup.string().ensure(),
  availableUntil: yup.date().nullable(),
  sourceURL: yup.string().ensure(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const libraryItemSchemaPartial = yup.object().shape(shape)
const libraryItemSchema = libraryItemSchemaPartial.shape(requiredFields(['providerId', 'title'])(shape))

module.exports = { libraryItemSchema, libraryItemSchemaPartial }
