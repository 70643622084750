const yup = require('yup')
const timeField = require('./time-field')

const dailyScheduleRef = yup.object().shape({
  start: timeField(),
  end: timeField(),
  rrule: yup.object().shape({
    freq: yup
      .number()
      .transform(() => 2)
      .default(2),
    interval: yup.number().default(1),
    byweekday: yup.array().default([0, 1, 2, 3, 4, 5, 6]),
  }),
})

const dailySchedules = () => yup.array().of(dailyScheduleRef).default([])

module.exports = { dailyScheduleRef, dailySchedules }
