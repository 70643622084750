import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'

const ActionHeader = ({ Icon, title = '', titleContent = '', setPageTitle = true, section = null, className = '', children = null }) => (
  <>
    {section && (
      <Typography variant="overline" gutterBottom={false}>
        {section}
      </Typography>
    )}
    <Box className={className} display="flex" alignItems="center" mb={2}>
      {setPageTitle && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Typography variant="h2">{titleContent || title}</Typography>
      {Boolean(Icon) && (
        <Box mx={2} display="inline-block">
          <Icon style={{ marginTop: 4, fontSize: 40 }} />
        </Box>
      )}
      <Box flex={1} />
      <Box display="flex">{children}</Box>
    </Box>
  </>
)

ActionHeader.propTypes = {
  Icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  titleContent: PropTypes.node,
  setPageTitle: PropTypes.bool,
  section: PropTypes.node,
  className: PropTypes.any,
  children: PropTypes.node,
}

export default ActionHeader
