const { memoize } = require('lodash')
const { find, values } = require('ramda')

const BRAND_QUILTT_DOMAIN = process.env.BRAND_QUILTT_DOMAIN
const BRAND_QUILTT_SMS_DOMAIN = process.env.BRAND_QUILTT_SMS_DOMAIN
const BRAND_HOAM_DOMAIN = process.env.BRAND_HOAM_DOMAIN
const BRAND_HOAM_SMS_DOMAIN = process.env.BRAND_HOAM_SMS_DOMAIN
const BRAND_CAMPUSHUB_DOMAIN = process.env.BRAND_CAMPUSHUB_DOMAIN
const BRAND_CAMPUSHUB_SMS_DOMAIN = process.env.BRAND_CAMPUSHUB_SMS_DOMAIN
const BRAND_ALPINE_DOMAIN = process.env.BRAND_ALPINE_DOMAIN
const BRAND_ALPINE_SMS_DOMAIN = process.env.BRAND_ALPINE_SMS_DOMAIN
const BRAND_QUILTT_EMAIL_FROM = process.env.BRAND_QUILTT_EMAIL_FROM
const BRAND_QUILTT_EMAIL_DOMAIN = process.env.BRAND_QUILTT_EMAIL_DOMAIN

const brands = {
  quiltt: {
    slug: 'quiltt',
    name: 'Quiltt',
    domain: BRAND_QUILTT_DOMAIN,
    smsDomain: BRAND_QUILTT_SMS_DOMAIN || BRAND_QUILTT_DOMAIN,
    url: (path = '/') => `https://manage.${BRAND_QUILTT_DOMAIN}${path}`,
    supportedDomains: ['quiltt.com'],
    emailDomain: BRAND_QUILTT_EMAIL_DOMAIN,
    systemEmail: BRAND_QUILTT_EMAIL_FROM,
  },
  hoam: {
    slug: 'hoam',
    name: 'HOAM',
    domain: BRAND_HOAM_DOMAIN,
    smsDomain: BRAND_HOAM_SMS_DOMAIN || BRAND_HOAM_DOMAIN,
    url: (path = '/') => `https://manage.${BRAND_HOAM_DOMAIN}${path}`,
    supportedDomains: ['hoam.tech', 'hoamapp.com'],
  },
  campushub: {
    slug: 'campushub',
    name: 'CampusHub',
    domain: BRAND_CAMPUSHUB_DOMAIN,
    smsDomain: BRAND_CAMPUSHUB_SMS_DOMAIN || BRAND_CAMPUSHUB_DOMAIN,
    url: (path = '/') => `https://manage.${BRAND_CAMPUSHUB_DOMAIN}${path}`,
    supportedDomains: ['campushub.io'],
  },
  alpine: {
    slug: 'alpine',
    name: 'Alpine Media',
    domain: BRAND_ALPINE_DOMAIN,
    smsDomain: BRAND_ALPINE_SMS_DOMAIN || BRAND_ALPINE_DOMAIN,
    url: (path = '/') => `https://manage.${BRAND_ALPINE_DOMAIN}${path}`,
    supportedDomains: ['alpinemedia.com'],
  },
}

brands.findFromHost = memoize((host = '') => {
  return find(({ supportedDomains = [] }) => find((domain) => host.includes(domain), supportedDomains), values(brands)) || brands.alpine
})

brands.getDomain = memoize((resort = {}) => {
  const { mobileEnableInBrandedApp: brand, mobileAppScheme, slug } = resort || {}
  return brand && brands[brand] ? `get.${brands[brand].smsDomain}` : `${mobileAppScheme || slug}.get.${BRAND_ALPINE_DOMAIN}`
})

module.exports = brands
