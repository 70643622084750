const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const mobileUserTypes = require('../mobile-user-types')
const { uploadSchema } = require('./cloudinary-schema')

const websiteSchema = yup.object().shape({
  label: yup.string().required('Website label is required').default(''),
  href: yup.string().required('Website address is required').default(''),
  openWebsiteInApp: yup.boolean().default(false),
})

const shape = labelify({
  title: yup.string().ensure(),
  description: yup.string().ensure(),
  attachment: uploadSchema,
  displayOnFeedScreen: yup.boolean().default(false),
  headingOnFeedScreen: yup.string().ensure(),
  pointOfInterest: yup.mixed(),
  phoneNumber: yup.string().ensure(),
  websites: yup.array().of(websiteSchema),
  resourceType: yup.string().oneOf(['url', 'attachment', 'pointOfInterest']).default('url'),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  sortOrder: yup.number().min(0),
  resourceGroup: yup.string().label('Resource'),
})

const resourceSchemaPartial = yup.object().shape(shape)
const resourceSchema = resourceSchemaPartial.shape(requiredFields(['title'])(shape))

const resourceFormSchema = resourceSchema.shape({
  pointOfInterest: yup.string().default(''),
})

module.exports = { resourceSchema, resourceFormSchema, websiteSchema, resourceSchemaPartial }
