const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')

const calendarOptions = yup.object().shape({
  paperSizeName: yup.string().ensure(),
  fontSizeName: yup.string().ensure(),
  paperOrientation: yup.string().ensure(),
  printSingleLine: yup.boolean(),
  theme: yup.mixed().transform((v) => v || null),
})

const shape = labelify({
  title: yup.string().default(''),
  subtitle: yup.string().default(''),
  date: yup.date().default(() => new Date()),
  shownOnMobile: yup.boolean().default(false),
  includeCalendar: yup.boolean().default(false),
  calendarDocDefinition: yup.mixed(),
  calendarOptions,
  calendars: yup.mixed().transform((v) => v || []),
  updateDownloadablePdf: yup.boolean().default(false),
  pdfDownload: uploadSchema,
  downloadUrl: yup.string().nullable(),
  editableLayout: yup.mixed(),
  duplicateOf: yup.mixed(),
  isFoldableBooklet: yup.boolean().default(false),
  published: yup.boolean(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const publicationSchemaPartial = yup.object().shape(shape)
const publicationSchema = publicationSchemaPartial.shape(requiredFields(['title', 'date'])(shape))

const publicationFormSchema = publicationSchema.shape({
  calendars: yup
    .array()
    .of(yup.string().ensure().label('Calendar'))
    .transform((value) => (!value || !value?.length ? [''] : value))
    .default(['']),
  calendarOptions: calendarOptions.shape({
    theme: yup.string().ensure(),
  }),
  date: yup.mixed().datetime(),
  published: yup.boolean().default(false),
})

module.exports = { publicationSchema, publicationSchemaPartial, publicationFormSchema }
