import * as actions from './actions'
import { saveEntities } from '../factories'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]
