import * as actions from './actions'
import { saveEntities } from '../factories'
import { sequences as route } from '../route'
import { showFeedback } from '../feedback/factories'
import { set } from 'cerebral/factories'
import { props } from 'cerebral'
import { loadProfilesSequence } from '../profiles/factories'
import { compose, path, pluck } from 'ramda'

const loadProfiles = loadProfilesSequence(compose(pluck('profile'), path(['props', 'entities', 'reservations'])), 'profileIds')

export const find = [
  actions.find,
  saveEntities(),
]

export const findForList = [
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
  loadProfiles,
]

export const get = [
  actions.get,
  saveEntities(),
]

export const save = [
  actions.save,
  actions.clearList,
  saveEntities(),
  showFeedback({ title: 'Reservation saved', type: 'success' }),
  route.toReservations,
]

export const remove = [
  actions.remove,
  showFeedback({ title: 'Reservation cleared', type: 'success' }),
  find,
]

export const cancel = [
  actions.cancel,
  showFeedback({ title: 'Reservation cancelled', type: 'success' }),
]
