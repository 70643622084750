const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  active: yup.boolean().default(true),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const mapAreaSchemaPartial = yup.object().shape(shape)
const mapAreaSchema = mapAreaSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { mapAreaSchema, mapAreaSchemaPartial }
