import { compose, path, pluck } from 'ramda'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import { loadProfilesSequence } from '../profiles/factories'
import * as actions from './actions'

const loadProfiles = loadProfilesSequence(compose(pluck('profile'), path(['props', 'entities', 'profileRelationships'])), 'profileIds')

export const find = [
  //
  actions.find,
  actions.filterProfiles,
  loadProfiles,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  loadProfiles,
  saveEntities(),
]

export const save = [
  //
  actions.save,
  saveEntities(),
  showFeedback({ title: 'Relationship saved', type: 'success' }),
]

export const remove = [
  //
  actions.remove,
  showFeedback({ title: 'Relationship deleted', type: 'success' }),
]
