const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.mixed(),
  status: yup.string().oneOf(['requesting', 'dispatched', 'en-route', 'completed', 'rejected', 'cancelled']).ensure().default('requesting'),
  profile: yup.mixed(),
  guestName: yup.object().shape({
    first: yup.string().default('').label('First name'),
    last: yup.string().default('').label('Last name'),
  }),
  guestCount: yup.number().min(1).default(1),
  locationRoute: yup.mixed(),
  locationVehicle: yup.mixed(),
  accessCode: yup.mixed(),
  phone: yup.string(),
  pickupLocation: yup.string().default(''),
  pickupCoordinates: yup.object().shape({
    latitude: yup.number().nullable(),
    longitude: yup.number().nullable(),
  }),
  destinationLocation: yup.string().when(['pickupLocation'], (pickupLocation) => {
    if (pickupLocation) {
      return yup
        .string()
        .notOneOf([yup.ref('pickupLocation')], `Destination location can't be same as pickup location.`)
        .default('')
    }
  }),
  destinationCoordinates: yup.object().shape({
    latitude: yup.number(),
    longitude: yup.number(),
  }),
  roundTripAvailableUntil: yup.date().nullable(),
  declinedAt: yup.date(),
  rejectedAt: yup.date(),
  rejectedReason: yup.string(),
  completedAt: yup.date(),
  createdAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationRouteRequestSchemaPartial = yup.object().shape(shape)
const locationRouteRequestSchema = locationRouteRequestSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationRouteRequestSchema, locationRouteRequestSchemaPartial }
