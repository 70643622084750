import { props } from 'cerebral'
import { set } from 'cerebral/factories'
import { saveEntities } from '../factories'
import { showFeedback } from '../feedback/factories'
import * as actions from './actions'

const saveEntitiesAndCollectIds = [
  //
  actions.collectIds,
  saveEntities(),
]

export const find = [
  //
  actions.find,
  saveEntitiesAndCollectIds,
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.clearList,
  actions.find,
  actions.setList,
  saveEntitiesAndCollectIds,
]

export const get = [
  //
  actions.get,
  saveEntitiesAndCollectIds,
]

export const save = [
  //
  actions.adjustAvailableUntil,
  actions.save,
  saveEntitiesAndCollectIds,
]

export const remove = [
  //
  actions.remove,
  actions.unsetCheck,
  showFeedback({ title: 'Library content deleted', type: 'success' }),
  findForList,
]

export const check = [
  //
  actions.check,
]
