import { connect } from '@cerebral/react'
import { Box, Chip, Divider, Grid, Tooltip, Typography, ListItem, ListItemText, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { state, sequences } from 'cerebral'
import { FieldArray } from 'formik'
import { isEmpty } from 'ramda'
import React, { useMemo, useEffect } from 'react'
import { capitalize } from 'underscore.string'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DragIndicator, Visibility, VisibilityOff } from '@mui/icons-material'
import SeasonSwitch from '../SeasonSwitch'
import DropUpload from '../../../controls/DropUpload'
import { omitStateFlag } from '../../../../lib/util/cerebral'

import schemaSettings from '../../../../../shared/schemas/resort-schema'
import ValidatedForm from '../../../blocks/ValidatedForm'
import { difference, keys, pick, pickBy, pluck } from 'ramda'

const { settingsFormSchema } = schemaSettings

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  active: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const sectionNames = {
  albums: 'Albums',
  surveys: 'Surveys',
  reservations: 'Reservations',
  weather: 'Weather',
  menu: 'Menu',
  liftsAndTrails: 'Lifts And Trails',
  snowConditions: 'Snow Conditions',
  events: 'Events',
  media: 'Media',
  mountainCams: 'Resort Cameras',
  resources: 'Resources',
  engagementBundle: 'Engagement Bundle',
}

const MobileAppSettings = connect(
  {
    organization: state`account.organization`,
    saveSettings: sequences`account.saveSettings`,
    serviceIntegrationSettings: state`account.currentUser.serviceIntegrationSettings`,
    sections: state`account.sections`,
    resortSeasons: state`seasons.list.data`,
    packages: state`account.packages`,
    findResortSeasons: sequences`seasons.findForList`,
  },
  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['seasons'], values) }),
  }),
  ({ organization, saveSettings, serviceIntegrationSettings, sections, resortSeasons, packages, findResortSeasons }) => {
    const classes = useStyles()
    const hasMultipleSeasons = useMemo(() => resortSeasons.length > 1, [resortSeasons])
    const hasMobileApp = packages['notifications']
    const displaySeasonToggle = hasMultipleSeasons && hasMobileApp

    useEffect(() => {
      findResortSeasons()
    }, [])

    return organization?._id ? (
      <ValidatedForm title="Mobile App Settings" section="Organization" initialValues={organization} schema={settingsFormSchema} onSubmit={saveSettings}>
        {({ setFieldValue, errors, values: { seasons = [] } }) => {
          const sectionsWithVirtual = useMemo(() => {
            const engagementBundle = Boolean(serviceIntegrationSettings.engagementBundle)
            return {
              ...sections,
              engagementBundle: engagementBundle ? true : undefined,
            }
          }, [sections, serviceIntegrationSettings])

          const sectionNamesWithOverrides = useMemo(() => {
            const { display: { name } = {} } = serviceIntegrationSettings.engagementBundle || {}
            if (name) {
              return { ...sectionNames, engagementBundle: name }
            }
            return sectionNames
          }, [serviceIntegrationSettings])

          const enabledMobileFeedSections = useMemo(() => keys(pickBy((v, k) => v && sectionNames[k], sectionsWithVirtual)), [sectionsWithVirtual])

          useEffect(() => {
            const seasons = resortSeasons.map((season) => {
              let { mobileFeedSections = [] } = season
              const currentFeedSections = pluck('mobileSection', mobileFeedSections || [])
              const notEnabledInFeed = difference(enabledMobileFeedSections, currentFeedSections)
              mobileFeedSections = mobileFeedSections.concat(notEnabledInFeed.map((mobileSection) => ({ mobileSection, visible: false })))
              return { ...season, mobileFeedSections }
            })

            setFieldValue('seasons', seasons)
          }, [enabledMobileFeedSections, resortSeasons, organization?.address, organization?.logo])

          return (
            <Box display="flex" flexDirection="column" flex={1}>
              <SeasonSwitch displayToggler={displaySeasonToggle} />
              <Grid spacing={2} container>
                {hasMobileApp
                  ? seasons.map((season, index) => {
                      const { _id: id, slug, active, coverPhoto, mobileHeaderImage, mobileFeedSections = [] } = season
                      const showActiveIndicator = active && hasMultipleSeasons

                      return (
                        <Grid key={id} item md={6} xs={12}>
                          <Box p={3} className={`${classes.paper} ${showActiveIndicator && classes.active}`}>
                            {displaySeasonToggle ? (
                              <Box display="flex" flexDirection="row" justifyContent="space-between" height={24} mb={2}>
                                <Typography variant="h5">{capitalize(slug)}</Typography>
                                <Box>{showActiveIndicator && <Chip color="primary" label="Active" />}</Box>
                              </Box>
                            ) : null}

                            <Grid md={12} xs={12} item style={{ marginBottom: 30 }}>
                              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                <Typography variant="h6">Cover Photo</Typography>
                                <Tooltip
                                  title={
                                    <>
                                      Shown at the top of the menu screen of the mobile app.
                                      <br />
                                      (Max size 10 MB; 5:3 aspect ratio)
                                    </>
                                  }
                                  arrow
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </Box>

                              <DropUpload
                                ratio={3 / 5}
                                crop="fill"
                                image={omitStateFlag(coverPhoto)}
                                onChange={(coverPhoto) => setFieldValue(`seasons.${index}.coverPhoto`, coverPhoto)}
                                error={errors?.seasons?.[index]?.coverPhoto}
                              />
                            </Grid>

                            <Grid md={12} xs={12} item>
                              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                <Typography variant="h6">Mobile Header Image</Typography>
                                <Tooltip
                                  title={
                                    <>
                                      Shown at the top of the main screen of the mobile app.
                                      <br />
                                      (Max size 10 MB; 1:1 aspect ratio)
                                    </>
                                  }
                                  arrow
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </Box>

                              <DropUpload
                                ratio={1}
                                crop="fill"
                                image={omitStateFlag(mobileHeaderImage)}
                                onChange={(mobileHeaderImage) => setFieldValue(`seasons.${index}.mobileHeaderImage`, mobileHeaderImage)}
                                error={errors?.seasons?.[index]?.mobileHeaderImage}
                              />
                            </Grid>

                            {isEmpty(mobileFeedSections) ? null : (
                              <>
                                <Box mt={3} mb={3}>
                                  <Divider />
                                </Box>

                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                  <Typography variant="h6">Mobile Feed Sections</Typography>
                                  <Tooltip title="You can sort the order and set visibility of sections shown on the mobile app feed screen" arrow>
                                    <InfoIcon />
                                  </Tooltip>
                                </Box>

                                <br />

                                <FieldArray
                                  name={`seasons.${index}.mobileFeedSections`}
                                  render={({ move }) => (
                                    <DragDropContext onDragEnd={({ source: { index: from }, destination: { index: to } }) => move(from, to)}>
                                      <Droppable droppableId="mobileSectionList">
                                        {(provided) => (
                                          <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {mobileFeedSections.map((section, sectionIndex) => {
                                              const { mobileSection } = section
                                              return (
                                                <Draggable key={mobileSection} draggableId={mobileSection} index={sectionIndex}>
                                                  {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                      <ListItem
                                                        disableGutters
                                                        component="div"
                                                        secondaryAction={
                                                          <Box display="flex" flexDirection="row" alignItems="center">
                                                            <IconButton
                                                              aria-label="visibility"
                                                              onClick={() =>
                                                                setFieldValue(`seasons.${index}.mobileFeedSections.${sectionIndex}.visible`, !section.visible)
                                                              }
                                                              size="large"
                                                            >
                                                              {section.visible ? <Visibility color="secondary" /> : <VisibilityOff />}
                                                            </IconButton>
                                                            <DragIndicator color="secondary" />
                                                          </Box>
                                                        }
                                                      >
                                                        <ListItemText primary={sectionNamesWithOverrides[mobileSection]} />
                                                      </ListItem>
                                                      <Divider />
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  )}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      )
                    })
                  : null}
              </Grid>
            </Box>
          )
        }}
      </ValidatedForm>
    ) : null
  }
)

export default MobileAppSettings
