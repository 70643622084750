import Color from 'color'
import { camelize } from 'underscore.string'

const createShortCode = (string) => {
  let caps = string.replace(/[^A-Z]+/g, '')
  if (caps.length < 2) {
    caps = string
  }
  return caps.slice(0, 2).toUpperCase()
}

export const mapEventLabel = ({ label, color, ...rest }) => ({
  ...rest,
  label,
  shortCode: createShortCode(label),
  color,
  textColor: Color(color).isDark() ? '#fff' : 'rgba(26, 25, 25, 1)', // Text color from theme.
  value: camelize(label, true),
})
