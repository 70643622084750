import { connect } from '@cerebral/react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { state } from 'cerebral'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'

const timeFormat = 'h:mma'

const HourMinutesText = connect(
  {
    timezone: state`account.currentUser.timezone`,
  },

  ({ field, form, timezone, label, size }) => {
    const value = useMemo(() => (field.value ? DateTime.fromFormat(field.value, timeFormat, { zone: timezone }) : null), [field.value, timezone])
    const onChange = useCallback(
      (dt) => {
        form.setFieldValue(field.name, dt ? dt.toFormat(timeFormat).toLowerCase() : dt)
      },
      [form, field]
    )

    return (
      <TimePicker
        size={size}
        label={label}
        value={value}
        onChange={onChange}
        slotProps={{
          actionBar: {
            actions: ['clear', 'accept'],
          },
        }}
      />
    )
  }
)

export default HourMinutesText
