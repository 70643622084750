import * as sequences from './sequences'

export default {
  state: {
    enabled: {},
    downloads: {},
    cards: [],
    messages: [],
    globalContentTemplate: {},
  },
  sequences,
}
