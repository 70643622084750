const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.mixed().required(),
  packages: yup.array().of(yup.string()).default([]),
  name: yup.object().shape(
    labelify({
      first: yup.string().default(''),
      last: yup.string().default(''),
      display: yup.string().default(''),
    })
  ),
  email: yup.string().email().required().default(''),
  mobileUser: yup.mixed(),
  mobileUserProfile: yup.mixed().default(''),
  // TODO: remove the user field after the migration to Auth0 is complete
  user: yup.mixed(),
  invitationToken: yup.string().nullable(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const accountSettingSchemaPartial = yup.object().shape(shape)
const accountSettingSchema = accountSettingSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { accountSettingSchema, accountSettingSchemaPartial }
