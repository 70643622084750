import { state } from 'cerebral'
import { isNil } from 'ramda'

export const locationVehiclesForMap = (get) => {
  const shownOnMap = get(state`locationVehicles.shownOnMap`) || {}
  const locationVehicles = get(state`locationVehicles.list.data`)
  return locationVehicles.filter(({ _id }) => shownOnMap[_id])
}

export const boundsForMap = (get) => {
  const locationVehicles = get(state`locationVehicles.locationVehiclesForMap`)
  const locations = locationVehicles.map(({ _id, lastLocation }) => {
    return { id: _id, ...lastLocation }
  })

  const bounds = locations.reduce((result, current) => {
    const { latitude, longitude } = current
    if (!isNil(latitude) && !isNil(longitude)) {
      if (result.length === 0) {
        return [
          [longitude, latitude],
          [longitude, latitude],
        ]
      } else {
        const [[minLng, minLat], [maxLng, maxLat]] = result

        return [
          [Math.min(minLng, longitude), Math.min(minLat, latitude)],
          [Math.max(maxLng, longitude), Math.max(maxLat, latitude)],
        ]
      }
    }

    return result
  }, [])

  return bounds
}
