const yup = require('yup')
const dayToggles = require('./fields/day-toggles')
const timeField = require('./fields/time-field')
const { labelify } = require('./helpers')

const emptyStringToNull = (v, o) => (o === '' ? null : v)

const packageSchema = yup.object().shape(
  labelify({
    title: yup.string().required().max(100).meta({ maxLength: 100 }).default(''),
    subtitle: yup.string().max(100).meta({ maxLength: 100 }).default(''),
    pricingResortArea: yup.mixed().default('').transform((v) => v && v._id || v),
    activeStartTime: timeField(),
    hideBefore: timeField(),
    hideAfter: timeField(),
    adultPrice: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    juniorPrice: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    seniorPrice: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    childPrice: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    subtitleHoliday: yup.string().max(100).meta({ maxLength: 100 }).default(''),
    activeStartTimeHoliday: timeField(),
    hideBeforeHoliday: timeField(),
    hideAfterHoliday: timeField(),
    adultPriceHoliday: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    juniorPriceHoliday: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    seniorPriceHoliday: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    childPriceHoliday: yup.number().min(0).nullable().transform(emptyStringToNull).default(0),
    displayOn: dayToggles(),
  })
)

const packageResponseSchema = packageSchema.shape({
  pricingResortArea: yup.object().shape({ name: yup.string() }).nullable(),
})

module.exports = { packageSchema, packageResponseSchema }
