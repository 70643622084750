import { connect } from '@cerebral/react'
import { props } from 'cerebral'
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Link,
  MenuItem,
  SvgIcon,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import { sequences, state } from 'cerebral'
import { omit, isEmpty, indexBy, prop, keys, values, clone } from 'ramda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Menu as MenuIcon } from 'react-feather'
import brand from '../../lib/util/brand'
import { omitConnectProps } from '../../lib/util/cerebral'
import DropDown from '../controls/DropDown'
import UserAvatar from '../elements/UserAvatar'
import { useAuth0 } from '@auth0/auth0-react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import throttle from 'lodash.throttle'
import apiClient from '../../lib/util/api-client'

const Logo = brand.logo

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    height: 64,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.palette.grey['200'],
  },
  logo: {
    height: 36,
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginLeft: -12, // Offset for button padding.
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

function getNestedResorts(resorts) {
  const nestedResorts = {}
  const resortEntities = indexBy(prop('_id'), resorts)
  const resortIds = keys(resortEntities)

  for (const resort of resorts) {
    const { _id, parent = '' } = resort

    // resort's parent must exist among granted resorts for this resort to be nested
    if (parent && resortIds.includes(parent.toString())) {
      if (!resortEntities[parent].resorts) {
        resortEntities[parent].resorts = []
      }
      resortEntities[parent].resorts.push(resortEntities[_id])
    } else {
      nestedResorts[_id] = resortEntities[_id]
    }
  }

  return values(nestedResorts)
}

const TitleForDropdown = connect(
  {
    type: state`account.${props`type`}`,
  },

  (props) => {
    const { type, showCaret = true, ...rest } = props
    return type ? (
      <Box display="flex" alignItems="center">
        <Typography variant="h4" {...omit(['get', 'reaction'], rest)}>
          {type.name}
        </Typography>
        {showCaret && <ArrowDropDown />}
      </Box>
    ) : null
  }
)

const ResortMenuItem = connect(
  {
    changeCalendar: sequences`calendars.changeCalendar`,
    changeMealLocation: sequences`mealLocations.changeMealLocation`,
    changeOrganization: sequences`account.changeOrganization`,
    setEventCalendars: sequences`events.setEventCalendars`,
    organization: state`account.organization`,
  },
  ({ changeCalendar, changeMealLocation, changeOrganization, setEventCalendars, organization = {}, id, name, slug, resorts = [] }) => {
    return (
      <>
        <MenuItem
          onClick={() => {
            setEventCalendars({ value: [''] })
            changeCalendar(null)
            changeMealLocation(null)
            changeOrganization({ orgSlug: slug })
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flex="1">
            <Box>{name}</Box>
            <Box pl={1} visibility={organization._id === id ? 'visible' : 'hidden'}>
              <CheckIcon />
            </Box>
          </Box>
        </MenuItem>

        {!isEmpty(resorts) ? (
          <Box marginLeft={2}>
            {resorts.map(({ _id: resortId, name: resortName, slug: resortSlug, resorts: resortsList = [] } = {}) => (
              <ResortMenuItem key={resortSlug} id={resortId} name={resortName} slug={resortSlug} resorts={resortsList} />
            ))}
          </Box>
        ) : null}
      </>
    )
  }
)

const TopBar = connect(
  {
    user: state`account.currentUser`,
    userChecked: state`account.checked`,
    organization: state`account.organization`,
    updateAvailable: state`ui.updateAvailable`,
    setUpdateAvailable: sequences`ui.setUpdateAvailable`,
    signOut: sequences`account.signOut`,
    mobileNavOpen: state`ui.mobileNavOpen`,
    setMobileNavOpen: sequences`ui.setMobileNavOpen`,
    navShown: state`route.navShown`,
    tenant: state`account.tenant`,
    toLogin: sequences`route.toLogin`,
  },

  (props) => ({ ...props, signOut: () => props.signOut() }),

  ({ user, userChecked, organization, setMobileNavOpen, mobileNavOpen, updateAvailable, setUpdateAvailable, signOut, tenant, navShown, toLogin, ...rest }) => {
    rest = omitConnectProps(rest)
    const classes = useStyles()
    const resortsList = useMemo(() => (organization ? getNestedResorts(clone(user.resorts)) : []), [user, organization])

    const { logout: logoutAuth0 } = useAuth0()

    const logOutCompletely = useCallback(async () => {
      await signOut()
      logoutAuth0({ logoutParams: { returnTo: window.location.origin, clientId: tenant.clientId } })
    }, [signOut, logoutAuth0])

    // If a user without access to Command Center reaches an internal view, redirect them to finish logging in.
    useEffect(() => {
      if (navShown && userChecked && !user?.canAccessCommandCenter) {
        toLogin()
      }
    }, [navShown, user, userChecked])

    // Service worker configuration from vite-plugin-pwa.
    const { updateServiceWorker } = useRegisterSW({
      onNeedRefresh: setUpdateAvailable,

      onRegistered: (swRegistered) => {
        const checkServiceWorker = () => {
          if (swRegistered) {
            swRegistered.update()
          }
        }

        const throttledCheckServiceWorker = throttle(checkServiceWorker, 5 * 60 * 1000, { leading: true, trailing: true })

        // Check for updates when focusing window.
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            throttledCheckServiceWorker()
          }
        })

        apiClient.io.on('connect', checkServiceWorker)
      },
    })

    const updateNow = useCallback(() => updateServiceWorker(true), [updateServiceWorker])

    return navShown ? (
      <AppBar elevation={0} className={classes.root} color="default" {...rest}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={() => setMobileNavOpen({ open: !mobileNavOpen })}
              size="large">
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>

          <Hidden lgDown>
            <Logo className={classes.logo} />
          </Hidden>

          {organization &&
            (resortsList < 2 ? (
              <TitleForDropdown showCaret={false} type={'organization'} />
            ) : (
              <DropDown
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                component={TitleForDropdown}
                componentProps={{ type: 'organization' }}
              >
                {resortsList.map(({ _id: id, name, slug, resorts = [] } = {}) => (
                  <Box key={slug} display="flex" flexDirection="column" flex="1">
                    <ResortMenuItem id={id} name={name} slug={slug} resorts={resorts} />
                  </Box>
                ))}
              </DropDown>
            ))}

          <Box flexGrow={1} />

          {updateAvailable && (
            <Box mx={2}>
              <Button onClick={updateNow}>Reload now to update?</Button>
            </Box>
          )}

          {user && (
            <>
              <DropDown
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                component={UserAvatar}
              >
                <MenuItem>
                  <Link color="textPrimary" underline="none" href={`mailto:${brand.email}`} target="_blank" rel="noopener noreferrer">
                    Email Support
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link color="textPrimary" underline="none" href="https://alpinemediatech.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </Link>
                </MenuItem>

                <MenuItem onClick={logOutCompletely}>Log Out</MenuItem>
              </DropDown>
            </>
          )}
        </Toolbar>
      </AppBar>
    ) : null;
  }
)

export default TopBar
