import { mapObjIndexed } from 'ramda'

export const createProviders = ({ name, service, extra = {}, schema }) => {
  // Providers are shared across modules, so they must be named uniquely.
  return {
    [`${name}Validation`]: {
      validate: (data) => schema.validate(data, { abortEarly: false, stripUnknown: true }),
      cast: (data) => schema.cast(data),
    },
    [`${name}Service`]: {
      create: (data) => service.create(data),
      update: (id, data) => service.update(id, data),
      patch: (id, data) => service.patch(id, data),
      get: (data) => service.get(data).then((result) => schema.cast(result)),
      find: (query) => service.find({ query }),
      remove: (id, params) => service.remove(id, params),
      ...extra,
    },
  }
}

export const storageProvider = ({ target }) => ({
  get: (key) => target.getItem(key),
  set: (key, value) => target.setItem(key, value),
  remove: (key) => target.removeItem(key),
})

export const storage = ({ sync, prefix = 'app', target = localStorage }) => {
  return ({ app, name }) => {
    if (sync) {
      // When app starts.
      app.once('initialized:model', () => {
        mapObjIndexed((storePath, storageKey) => {
          try {
            storageKey = `${prefix}.${storageKey}`
            const value = JSON.parse(target.getItem(storageKey))
            if (value !== null) {
              app.model.set(storePath.split('.'), value)
            }
          } catch (error) {
            console.error(error)
          }
        }, sync)
      })

      // When changes are flushed to UI.
      app.on('flush', (changes) => {
        changes.forEach(({ path }) => {
          mapObjIndexed((storePath, storageKey) => {
            try {
              if (path.join('.').startsWith(storePath)) {
                const value = app.getState(storePath)
                storageKey = `${prefix}.${storageKey}`

                if (value === undefined) {
                  target.removeItem(storageKey)
                } else {
                  target.setItem(storageKey, JSON.stringify(value))
                }
              }
            } catch (error) {
              console.error(error)
            }
          }, sync)
        })
      })
    }

    return {
      providers: {
        [name]: storageProvider({ target }),
      },
    }
  }
}
