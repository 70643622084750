import { useCallback, useEffect, useRef, useState } from 'react'

export const useToggleState = (initial) => {
  const mounted = useRef(true)
  useEffect(() => () => mounted.current = false, [])
  const [visible, __setVisible] = useState(initial)
  // Do not trigger state update if component is unmounted.
  const setVisible = (v) => mounted.current && __setVisible(v)
  const open = useCallback(() => setVisible(true), [setVisible])
  const close = useCallback(() => setVisible(false), [setVisible])
  const toggle = useCallback(() => { setVisible(!visible) }, [setVisible, visible])
  return [visible, open, close, setVisible, toggle]
}
