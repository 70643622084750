import { crudActions } from '../factories'

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'profileRelationships' })

export const filterProfiles = ({ props: { query: { profiles } = {}, entities = {} } = {} }) => {
  let { profileRelationships = [] } = entities
  profileRelationships = profileRelationships.map((relationship) => {
    const profile = relationship.profiles?.filter((profile) => profile !== profiles)[0]
    return { ...relationship, profile }
  })
  return { entities: { ...entities, profileRelationships } }
}
