const yup = require('yup')
const { labelify } = require('./helpers')
let { resortStatSchema } = require('./resort-stat-schema')
const { uploadSchema } = require('./cloudinary-schema')

resortStatSchema = resortStatSchema.shape({
  _id: yup.string(),
})

const mobileFeedSectionSchema = yup.object().shape({
  mobileSection: yup.string().ensure(),
  visible: yup.boolean().default(false),
})

const shape = labelify({
  resort: yup.mixed(),
  slug: yup.string(),
  activeAfter: yup.date(),
  active: yup.boolean().default(false),
  coverPhoto: uploadSchema,
  mobileHeaderImage: uploadSchema,
  holidayPricingOnWeekends: yup.mixed(),
  holidayPricingOnFridays: yup.mixed(),
  holidays: yup.mixed(),
  featureStats: yup.array().of(resortStatSchema).default([]),
  statsHeading: yup.string().ensure().max(40).meta({ maxLength: 40 }),
  stats: yup.array().of(resortStatSchema).default([]),
  secondaryStatsHeading: yup.string().ensure().max(40).meta({ maxLength: 40 }),
  secondaryStats: yup.array().of(resortStatSchema).default([]),
  hiddenStats: yup.array().of(resortStatSchema).default([]),
  conditionsOverride: yup.string().ensure(),
  mobileFeedSections: yup.array().of(mobileFeedSectionSchema).default([]),
})

const seasonSchema = yup.object().shape(shape)

module.exports = { seasonSchema }
