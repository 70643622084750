import { moduleState, state } from 'cerebral'
import { crudActions } from '../factories'
import format from '../../../shared/format'
import { propEq, find as findR } from 'ramda'

export const { find, setList, clearList, get, save, remove, setFormDefaults } = crudActions({ name: 'updates' })

export const getDisplaysCount = async ({ store, displaysService }) => {
  const { count } = await displaysService.find()
  store.set(moduleState`displaysCount`, count)
}

export const initBirthdayDefaults = async ({ props: { profileId } = {}, get }) => {
  const todaysBirthdays = get(state`profiles.todaysBirthdays`)
  const profiles = get(state`profiles.entities`)
  const profile = findR(propEq(profileId, '_id'), todaysBirthdays) || profiles[profileId]

  if (profile && profile?.name) {
    const { name } = profile
    const displayName = format.name(name)
    return {
      values: {
        primary: `Happy Birthday ${displayName}`,
        details: `🎉 Happy Birthday ${displayName}! 🎉\n\nWishing you a day filled with joy, laughter, and unforgettable moments. Your presence brightens our community! May this year bring you health, happiness, and the warmth of good company.\nCheers to many more! 🍰🎈`,
        expires: 'midnight',
        updateType: 'persistent',
        displayLocation: 'signage',
        filterDevices: 'all',
      },
    }
  }
}
